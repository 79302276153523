<template>
	
	<div class="container-fluid">
		<h4 class="c-grey-900 mT-10 mB-30">{{ $t('mytickets_1') }}</h4>
		<div class="row">
			<div class="col-md-12 label-weight">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<h4 class="c-grey-900 mB-20">{{ $t('mytickets_2') }}</h4>
					<p>{{ $t('mytickets_3') }}</p>
					<div class="row">
						<div class="col-md-8">
							<form autocomplete="off" @submit.prevent="SubmitTicket" method="post">
							
								<div class="row">
									<div class="form-group col-md-6">
										<label for="contactEmail">{{ $t('mytickets_4')[0] }} <b class="text-danger" title="required field">*</b></label>
										
										<input type="text" :class="['form-control', validateArray.email != '' ? 'is-invalid' : '']" id="contactEmail" :placeholder="$t('mytickets_4')[1]" v-model="requestData.email" required />
										<small class="form-text text-muted">{{ $t('mytickets_4')[2] }}</small>
									</div>
									
									<div class="form-group col-md-6">
										<label for="Department">{{ $t('mytickets_5') }}<b class="text-danger" title="required field">*</b></label>
										<select id="Department" v-model="requestData.department" :class="['form-control', validateArray.department != '' ? 'is-invalid' : '']" required>
											<option disabled> {{ $t('mytickets_6')[0] }} </option>
											<option value="GeneralQuestion">{{ $t('mytickets_6')[1] }}</option>
											<option value="TechnicalSupport">{{ $t('mytickets_6')[2] }}</option>
											<option value="PaymentBilling">{{ $t('mytickets_6')[3] }}</option>
											<option value="AccountDetails">{{ $t('mytickets_6')[4] }}</option>
											<option value="Renewal">{{ $t('mytickets_6')[5] }}</option>
										</select>
										<small class="form-text text-muted">{{ $t('mytickets_6')[6] }}</small>
									</div>
								</div>
								
								<!--div class="row">
									<div class="form-group col-md-12">
										<label for="attachment">Attachment (Optional)</label>
										<input type="file" class="form-control" id="attachment" style="height: 42px;" required />
										<small class="form-text text-muted">Accept : Images, PDF, CSV, TXT, ZIP Maximum size: 10.00MB</small>
									</div>
								</div-->
								
								<div class="row">
									<div class="form-group col-md-12">
										<label for="subject">{{ $t('mytickets_7')[0] }}<b class="text-danger" title="required field">*</b></label>
										<input type="text" id="subject" v-model="requestData.subject" :placeholder="$t('mytickets_7')[1]" :class="['form-control', validateArray.subject != '' ? 'is-invalid' : '']" required />
									</div>
								</div>
								
								<div class="row">
									<div class="form-group col-md-12">
										<label for="message">{{ $t('mytickets_8')[0] }}<b class="text-danger" title="required field">*</b></label>
										<textarea id="message" :placeholder="$t('mytickets_8')[1]" v-model="requestData.message" rows="6" :class="['form-control', validateArray.message != '' ? 'is-invalid' : '']" required></textarea>
									</div>
								</div>
								
								<div class="row">
									<div class="form-group col-md-12 text-right">
										<button class="btn btn-info" type="submit" :disabled="loading">
											<i :class="['fa', loading ? 'fa-circle-o-notch fa-spin' : 'fa-send']"></i> {{ $t('mytickets_9') }}
										</button>
									</div>
								</div>
								<div>
									<hr><small>{{ $t('mytickets_10') }}</small>
								</div>
								
							</form>
						</div>
						<div class="col-md-4">
							<h4>{{ $t('mytickets_11') }}</h4>
							<p>{{ $t('mytickets_12') }}</p>
							<p>{{ $t('mytickets_13') }}</p>
							<p>{{ $t('mytickets_14') }}</p>
						</div>
						
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	
	export default {
		name: 'NewTicket',
		data() {
			return {
				loading		: false,
				requestData	: {
					email		: '',
					department	: 'GeneralQuestion',
					attachment 	: '',
					subject  	: '',
					message   	: ''
				},
				validateArray: { email: '', department: '', subject: '', message: '' }
			}
		},
		methods: {
			SubmitTicket() {
				
				let that = this
				let validateArray= that.validateArray
				Object.keys(validateArray).forEach(function(field) {
					that.validateArray[field] = ''
				})
				
				that.loading = true
				let url  	 = '/account/submit_ticket'
				let method   = 'POST'
				
				let data 	= { email: that.requestData.email, department: that.requestData.department, subject: that.requestData.subject, message: that.requestData.message }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					if(response.data.message == 'success' ) {
						that.$router.push({path: 'help'})
					}
					that.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.loading = false
				} )
				
			}
		},
		mounted() {
			let that = this
			that.$store.state.selectedPage = that.$route.name
			that.requestData.email = that.$store.state.user.email
			that.$store.state.loading = false
		},
		components: {
			//
		}
	}
</script>
<style lang="scss">
	
</style>