<template>
	
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="flex-container lead-info space-between">
					<div class="flex-container">
						<!--img src="http://placehold.it/380x500" alt="" class="bdrs-50p img-lead" /-->
						<avatar :fullname="ImgContent" :size="100" :fontSize="45"></avatar>
						<div class="pL-20 lead-card">
							<h4 class="m-0">
								<i class="fa fa-user-o fs-18" v-if="leadStatus.color == '#ffffff'"></i>
								<i class="fa fa-user fs-18" v-else :style="{color: leadStatus.color}"></i>&nbsp;
								<span v-if="LeadObject.full_name != '' && LeadObject.full_name != null">{{ LeadObject.full_name }}</span>
								<span v-else>{{ LeadObject.first_name }} {{ LeadObject.last_name }}</span>&nbsp;
								<span v-if="LeadObject.duplicate.length > 1" style=" vertical-align: middle; " class="badge badge-danger fs-10">{{ $t('myleads_9') }} ({{LeadObject.duplicate.length}})</span>
							</h4>
							<!--small><cite title="San Francisco, USA">San Francisco, USA <i class="ti-map-alt"></i></cite></small-->
							<small><span>{{ LeadObject.generated_at | date_format }}</span> <i class="ti-time"></i></small>
							<div class="contact-info">
								<div><i class="fa fa-envelope-o"></i> <span>{{ LeadObject.email }}</span></div>
								<div><i class="fa fa-phone"></i> <span>{{ LeadObject.phone }}</span></div>
							</div>
						</div>
					</div>
					<div>
						<drop-options v-model="leadStatus.title" :options="StatusOptions">
							<template v-slot:title>
								<span>
									<i class="fa fa-circle-o" v-if="leadStatus.color == '#ffffff'"></i> 
									<i class="fa fa-circle" v-else :style="{color: leadStatus.color}"></i> 
									{{ leadStatus.title }}
								</span>
							</template><!-- Object.keys(MyStatuses) -->
							<template v-slot:options="slotProps">
								<li v-for="(status, id) in slotProps.options" 
								v-bind:key="`li-${id}`" @click="ChangeStatus(status.option)" :class="[status.option == LeadObject.status ? 'active':'']">
									<a href="javascript:void(0);" class="d-b td-n pY-5 pX-15 c-grey-700">
										<!--i :class="[ status.icon == null ? '' : status.icon, 'mR-10']"></i-->
										<!--span>{{ status }}</span-->
										<i class="fa fa-circle-o" v-if="MyStatuses[status.option]['color'] == '#ffffff'"></i>
										<i class="fa fa-circle" v-else :style="{color: MyStatuses[status.option]['color']}"></i>&nbsp;
										<span v-html="status.title"></span>
									</a>
								</li>
							</template>
						</drop-options>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 mt-4">
				<tabs :Items="tabsItems" :fill="false" @change="ChangeTabs" :current="currentTab"></tabs>
				<div class="tabs-content p-15 label-weight">
					
					<div v-show="currentTab == 1">
						<general-details :Lead="Lead" @onChange="ChangeDetails"></general-details>
					</div>
					<div v-show="currentTab == 2">
						<custom-fields :custom_fields="custom_fields" :MyCustomFields="MyFields" @onChange="ChangeDetails" :lead_id="Lead.id"></custom-fields>
					</div>
					<div v-show="currentTab == 3">
						<my-activity :Lead="Lead" :MyStatuses="MyStatuses"></my-activity>
					</div>
					<div v-show="currentTab == 4">
						<notes></notes>
					</div>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { Tabs, DropOptions, Avatar } from "@/components";
	import GeneralDetails 	from "./LeadDetails/GeneralDetails.vue";
	import CustomFields 	from "./LeadDetails/CustomFields.vue";
	import MyActivity 		from "./LeadDetails/MyActivity.vue";
	import Notes 			from "./LeadDetails/Notes.vue";
	export default {
		name : 'LeadDetails',
		components: {
			Tabs,
			GeneralDetails,
			CustomFields,
			MyActivity,
			Notes,
			DropOptions,
			Avatar
		},
		props: {
			Lead		: { type: Object },
			MyStatuses	: { type: Object },
			MyFields	: { type: Array }
		},
		data() {
			return {
				tabsItems 	 : [
					{ id: 1, label: this.$t('leaddetails_1')[0],	enable:	true ,	count: ''  },
					{ id: 2, label: this.$t('leaddetails_1')[1],	enable:	false,	count: ''  },
					{ id: 3, label: this.$t('leaddetails_1')[2],	enable:	false,	count: '' },
					// { id: 4, label: "Notes", 		  	enable:	false, 	count: '' },
				],
				currentTab	 : 1,
				LeadObject	 : { id: '', full_name: '', first_name: '', last_name: '', email: '', phone: '', generated_at: '', status: '', duplicate: [] },
				leadStatus	 : { title: '', color: '' },
				custom_fields: {},
				lead_activity: [],
				
				StatusOptions: []
			}
		},
		computed : {
			ImgContent() {
				let that = this, text = ''
				if( that.LeadObject.full_name != '' && that.LeadObject.full_name != null ) {
					text = that.LeadObject.full_name
				} else if( that.LeadObject.first_name != '' || that.LeadObject.first_name != null || that.LeadObject.last_name != null || that.LeadObject.last_name != null ) {
					text = that.LeadObject.first_name + ' ' + that.LeadObject.last_name
				} else if( that.LeadObject.email != '' && that.LeadObject.email != null ) {
					text = that.LeadObject.email
				}
				return text
			}
		},
		methods: {
			ChangeDetails( field, NewValue, action, entries ) {
				let that = this
				if( action == 'GeneralDetails' ) {
					that.LeadObject[field] 	= NewValue
				}
				that.$emit('onChange', field, NewValue, action, entries)
			},
			ChangeStatus(element) {
				let that = this
				that.$store.state.loading = true
				if( that.LeadObject.status != element ) {
					let OldValue = that.LeadObject.status
					that.LeadObject.status = element
					that.leadStatus.title  = that.MyStatuses[that.LeadObject.status].title
					that.leadStatus.color  = that.MyStatuses[that.LeadObject.status].color
					
					let url  	= '/leads/update'
					let method  = 'PUT'
					let data 	= { id : that.LeadObject.id, field : 'status', NewValue : element, OldValue : OldValue, action : 'ChangeStatus' }
					let request = { url: url, method: method, data: data }
					that.$store.dispatch('requests', request).then((response) => {
						if( response.data.status == 'success' ) {
							let entries = response.data.entries
							that.$emit('onChange', 'status', element, 'ChangeStatus', entries)
							// that.ToastNotify('success', 'Success', 'Changes saved')
						} else {
							that.ToastNotify('warning', 'Opps!', response.data.message)
						}
						that.$store.state.loading = false
					}).catch(({response}) => {
						that.ErrorHandler(response)
						that.$store.state.loading = false
					} )
				}
			},
			ChangeTabs(tab) {
				let that = this
				that.currentTab = tab.id
			},
		},
		watch: {
			
			Lead: {
				handler(val) {
					let that = this
					
					if( that.LeadObject.id != val.id ) {
						that.currentTab				 = 1
					}
					that.LeadObject.id 			 = val.id
					that.LeadObject.full_name 	 = val.full_name
					that.LeadObject.first_name   = val.first_name
					that.LeadObject.last_name 	 = val.last_name
					that.LeadObject.email 		 = val.email
					that.LeadObject.phone 		 = val.phone
					that.LeadObject.generated_at = val.generated_at
					that.LeadObject.status		 = val.status
					that.LeadObject.duplicate	 = val.duplicate
					
					that.custom_fields			 = val.custom_fields
					that.tabsItems[2].count 	 = val.lead_activity.length + 1
					
					if( that.LeadObject.status != '' ) {
						that.leadStatus.title = that.MyStatuses[that.LeadObject.status].title
						that.leadStatus.color = that.MyStatuses[that.LeadObject.status].color
					}
				},
				deep: true
			},
			MyStatuses(statuses) {
				let that = this
				that.StatusOptions = []
				Object.keys(statuses).forEach( function( status ) {
					that.StatusOptions.push({ option: status, title: statuses[status].title })
				} )
			}
		},
		mounted() {
			
		}
	}
</script>
<style lang="scss">
	.flex-container {
		display: flex;
		&.space-between {
			justify-content: space-between;
		}
		&.wrap {
			flex-wrap: wrap;
		}
	}
	.lead-status-change {
		option:after {
			/* content: " ";
			height: 5px;
			width: 5px;
			background: #c00;
			border-radius: 5px;
			display: inline-block; */
			
			position: absolute;
			margin-left: 16px;
			top: 0px;
			
			border-bottom: 10px solid transparent;
			border-top: 10px solid transparent;
			border-left: 0px solid transparent;
			border-right: 8px solid #171717;
			
			content: "";
			/* display: none; */
			-webkit-transition: 0.25s ease-in-out;
			transition: 0.25s ease-in-out;
			
		}
	}
	.lead-info {
		.lead-card {
			.contact-info {
				div {
					i {
						width:16px;
					}
				}
			}
		}
	}
	.tabs-content {
		border: 1px solid #dee2e6;
		margin-top: -1px;
		min-height: 48vh;
	}
	
	.dropdown-options {
		.list-options {
			ul {
				li.active {
					background-color: #f5f5f5;
				}
			}
		}
	}
	
</style>
