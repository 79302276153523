<template>
	<div>
		<template v-for="(item, id) in FieldsObject">
			<div class="row form-group" v-bind:key="`cField-${id}`"><!-- v-if="item.Value != '' && item.Value != null" -->
				<div class="col-md-3 my-auto">
					<label :for="item.Field">{{ item.Field }}</label>
				</div>
				<div class="col-md-6">
					<div class="input-group">
						<input type="text" class="form-control" :id="item.Field" :placeholder="item.Field" @blur="EditCustomField(item)" autocomplete="off" v-model.trim="item.Value" />
						<div class="input-group-append">
							<span class="input-group-text bg-white px-18">
								<i v-if="item.loading" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-list"></i>
							</span>
						</div>
					</div>
				</div>
				<div class="col-md-3 my-auto">
					<span class="text-success" v-if="item.changed">
						<i class="fa fa-check"></i> Changes Saved
					</span>
				</div>
			</div>
		</template>
		<div class="row mB-5">
			<div class="col-md-3 my-auto">
				<label>Field Name:</label>
			</div>
			<div class="col-md-6">
				<label>Field Value:</label>
			</div>
			<div class="col-md-3 my-auto">
				<span class="btn-link text-decoration-none cur-p" @click="AddNewField">
					<i class="fa fa-plus-circle"></i> Add new field
				</span>
			</div>
		</div>
		<div class="row form-group" v-for="(field, id) in NewFields" v-bind:key="`newField-${id}`">
			<div class="col-md-3 my-auto">
				<!--input type="text" class="form-control" placeholder="field name" autocomplete="off" v-model="field.FieldName" /-->
				<auto-complete :items="MyCustomFields" placeholder="field name" @input="customInput()" v-model="field.FieldName" :defaultData="field.FieldName" :disabled="field.saved" :invalid="field.invalid">
				</auto-complete>
			</div>
			<div class="col-md-6">
				<div class="input-group">
					<input type="text" :class="['form-control', field.isEmpty ? 'is-invalid' : '']" placeholder="field value" autocomplete="off" v-model.trim="field.FieldValue" :disabled="field.saved" />
					<div class="input-group-append">
						<span class="input-group-text bg-white px-18">
							<i v-if="field.loading" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-pencil-square-o"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-3 my-auto">
				<i class="ti-save text-success mR-8 brd-s bdrs-50p p-5 cur-p" @click="SaveField(field, id)" v-if="!field.saved"></i>
				<i class="ti-trash text-danger mR-8 brd-s bdrs-50p p-5 cur-p" @click="RemoveField(field, id)" v-if="!field.saved"></i>
				<!--i class="ti-pencil text-primary mR-8 brd-s bdrs-50p p-5 cur-p" @click="EditField(field, id)" v-else></i-->
				<span class="text-success" v-if="field.saved">
					<i class="fa fa-check"></i> Field Saved
				</span>
			</div>
		</div>
	</div>
</template>
<script>
	import { AutoComplete } from '@/components'
	export default {
		name	  : 'CustomFields',
		components: {
			AutoComplete
		},
		props	  : {
			custom_fields : {type:Object},
			MyCustomFields: {type:Array},
			lead_id		  : {type:Number}
		},
		data() {
			return {
				FieldsObject : [],
				NewFields	 : [],
				LeadId		 : ''
			}
		},
		methods: {
			EditCustomField(element) {
				let that = this, MyField = element.Field, OldValue = that.custom_fields[MyField], NewValue = element.Value
				if( NewValue != OldValue && that.lead_id > 0 ) {
					
					element.changed = false
					element.loading = true
					let url  		= '/leads/update'
					let method  	= 'PUT'
					let data 		= { id : that.lead_id, field : MyField, NewValue : NewValue, OldValue : OldValue, action : 'CustomFields' }
					let request 	= { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						element.changed = true
						element.loading = false
						that.$emit('onChange', MyField, NewValue, 'CustomFields', response.data.entries )
					}).catch(({response}) => {
						that.ErrorHandler(response)
						element.loading = false
					} )
				}
			},
			SaveField(field) { //, id
				let that = this
				
				field.invalid = field.FieldName == ''
				field.isEmpty = field.FieldValue == ''
				
				if( field.FieldName != '' && field.FieldValue != '' ) {
					
					field.loading = true
					field.saved   = false
					
					let url  		= '/leads/update'
					let method  	= 'PUT'
					let data 		= { id : that.lead_id, field : field.FieldName, NewValue : field.FieldValue, OldValue : '', action : 'NewCustomFields' }
					let request 	= { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						let Status = response.data.status
						field.loading = false
						if( Status == 'success' ) {
							field.saved   = true
							that.FieldsObject.push({Field: field.FieldName, Value: field.FieldValue, loading: false, changed: true})
							that.$emit('onChange', field.FieldName, field.FieldValue, 'NewCustomFields', response.data.entries )
							that.NewFields.splice(0, 1)
						} else {
							that.ToastNotify( 'warning', 'Opps!', response.data.message )
						}
						
					}).catch(({response}) => {
						that.ErrorHandler(response)
						field.loading = false
					} )
					
					
				}
			},
			EditField(field) { //, id
				field.saved = false
			},
			RemoveField(field, id) {
				let that = this
				that.NewFields.splice(id, 1)
				console.log(field.loading)
			},
			
			AddNewField() {
				let that = this
				that.NewFields.push({id: that.NewFields.length + 1, FieldName: '', FieldValue: '', saved: false, invalid: false, isEmpty: false, loading: false})
			},
			customInput() {
				
			}
		},
		watch : {
			
			custom_fields: {
				handler(MyObject) {
					let that = this
					if( that.LeadId != that.lead_id ) {
						that.LeadId = that.lead_id
						that.FieldsObject = []
						that.NewFields 	  = []
						Object.keys( MyObject ).forEach( function(item) {
							that.FieldsObject.push({Field: item, Value: MyObject[item], loading: false, changed: false})
						} )
					}
				},
				deep:true
			}
		},
		computed : {
			
		},
	}
</script>
