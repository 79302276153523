<template>
	<div>
		<div class="row form-group">
			<div class="col-md-3 my-auto">
				<label for="Listsource">{{ $t('generaldtls_1')[0] }}</label>
			</div>
			<div class="col-md-6">
				<input type="text" class="form-control" id="Listsource" :placeholder="$t('generaldtls_1')[1]" autocomplete="off" :value="LeadObj.list_name" disabled />
			</div>
			<div class="col-md-3 my-auto"></div>
		</div>
		<div class="row form-group">
			<div class="col-md-3 my-auto"><!-- form-group -->
				<label for="fullnameDetail">{{ $t('generaldtls_2')[0] }}</label>
			</div>
			<div class="col-md-6">
				<div class="input-group">
					<input type="text" class="form-control" id="fullnameDetail" @blur="changeDetails('full_name')" :placeholder="$t('generaldtls_2')[1]" autocomplete="off" v-model.trim="LeadObj.full_name" />
					<div class="input-group-append">
						<span class="input-group-text bg-white px-18">
							<i v-if="LoadingProc.full_name" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-id-card-o"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-3 my-auto">
				<span class="text-success" v-if="ChangesSaved.full_name">
					<i class="fa fa-check"></i> {{ $t('generaldtls_2')[2] }}
				</span>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-3 my-auto">
				<label for="firstnameDetail">{{ $t('generaldtls_3')[0] }}</label>
			</div>
			<div class="col-md-6">
				<div class="input-group">
					<input type="text" class="form-control" id="firstnameDetail" @blur="changeDetails('first_name')" :placeholder="$t('generaldtls_3')[1]" autocomplete="off" v-model.trim="LeadObj.first_name" />
					<div class="input-group-append">
						<span class="input-group-text bg-white px-18">
							<i v-if="LoadingProc.first_name" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-id-card-o"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-3 my-auto">
				<span class="text-success" v-if="ChangesSaved.first_name">
					<i class="fa fa-check"></i> {{ $t('generaldtls_3')[2] }}
				</span>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-3 my-auto">
				<label for="lastnameDetail">{{ $t('generaldtls_4')[0] }}</label>
			</div>
			<div class="col-md-6">
				<div class="input-group">
					<input type="text" class="form-control" id="lastnameDetail" @blur="changeDetails('last_name')" :placeholder="$t('generaldtls_4')[1]" autocomplete="off" v-model.trim="LeadObj.last_name" />
					<div class="input-group-append">
						<span class="input-group-text bg-white px-18">
							<i v-if="LoadingProc.last_name" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-id-card-o"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-3 my-auto">
				<span class="text-success" v-if="ChangesSaved.last_name">
					<i class="fa fa-check"></i> {{ $t('generaldtls_4')[2] }}
				</span>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-3 my-auto">
				<label for="emailAddressDetail">{{ $t('generaldtls_5')[0] }}</label>
			</div>
			<div class="col-md-6">
				<div class="input-group">
					<input type="text" class="form-control" id="emailAddressDetail" @blur="changeDetails('email')" :placeholder="$t('generaldtls_5')[1]" autocomplete="off" v-model.trim="LeadObj.email" />
					<div class="input-group-append">
						<span class="input-group-text bg-white px-18">
							<i v-if="LoadingProc.email" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-envelope-o"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-3 my-auto">
				<span class="text-success" v-if="ChangesSaved.email">
					<i class="fa fa-check"></i> {{ $t('generaldtls_5')[2] }}
				</span>
			</div>
		</div>
		<div class="row form-group">
			<div class="col-md-3 my-auto">
				<label for="phoneNumberDetail">{{ $t('generaldtls_6')[0] }}</label>
			</div>
			<div class="col-md-6">
				<div class="input-group">
					<input type="text" class="form-control" id="phoneNumberDetail" @blur="changeDetails('phone')" :placeholder="$t('generaldtls_6')[1]" autocomplete="off" v-model.trim="LeadObj.phone" />
					<div class="input-group-append">
						<span class="input-group-text bg-white px-18">
							<i v-if="LoadingProc.phone" class="fa fa-cog fa-spin"></i><i v-else class="fa fa-phone"></i>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-3 my-auto">
				<span class="text-success" v-if="ChangesSaved.phone">
					<i class="fa fa-check"></i> {{ $t('generaldtls_6')[2] }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'LeadOverview',
		components: {
			
		},
		props: {
			Lead: { type: Object }
		},
		data() {
			return {
				LeadObj	 	: { id: '', full_name: '', first_name: '', last_name: '', email: '', phone: '', list_name: '' },
				LoadingProc	: { full_name: false, first_name: false, last_name: false, email: false, phone: false, list_name: false },
				ChangesSaved: { full_name: false, first_name: false, last_name: false, email: false, phone: false, list_name: false },
			}
		},
		computed : {
			
		},
		methods: {
			changeDetails(element) {
				let that = this, OldValue =  that.Lead[element], NewValue =  that.LeadObj[element].trim()
				if( OldValue != NewValue ) {
					
					that.ChangesSaved[element] = false
					that.LoadingProc[element]  = true
					
					let url  	= '/leads/update'
					let method  = 'PUT'
					let data 	= { id : that.LeadObj.id, field : element, NewValue : NewValue, OldValue : OldValue, action : 'GeneralDetails' }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then((response) => {
						if( response.data.status == 'success' ) {
							that.ChangesSaved[element] = true
							that.$emit('onChange', element, NewValue, 'GeneralDetails', response.data.entries)
						} else {
							that.ToastNotify('warning', 'Opps!', response.data.message)
						}
						that.LoadingProc[element] = false
					}).catch(({response}) => {
						that.ErrorHandler(response)
						that.LoadingProc[element] = false
					} )
					
				}
			}
		},
		watch: {
			/* lead: {
				handler: function(val) {
					this.LeadObj = val
				},
				deep: true
			}, */
			
			Lead: {
				handler(val) {
					let that = this
					if( that.LeadObj.id != val.id ) {
						that.LoadingProc	= { full_name: false, first_name: false, last_name: false, email: false, phone: false, list_name: false }
						that.ChangesSaved	= { full_name: false, first_name: false, last_name: false, email: false, phone: false, list_name: false }
					}
					
					that.LeadObj.id 		= val.id
					that.LeadObj.full_name 	= val.full_name
					that.LeadObj.first_name = val.first_name
					that.LeadObj.last_name 	= val.last_name
					that.LeadObj.email 		= val.email
					that.LeadObj.phone 		= val.phone
					that.LeadObj.list_name 	= val.list_name
					
				},
				deep:true
			}
		}
	}
</script>
