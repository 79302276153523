<template>
	<div>
		<div class="flex-container wrap">
			<div id="add-note" @click="addNotes()">+</div> 
			<div class="notearea" v-for="(item, id) in MyNotes" v-bind:key="`note-${id}`">
				<div>
					<small>{{ item.date }}</small>
					<span class="fs-20 pull-right cur-p close-note">&times;</span>
				</div>
				<textarea placeholder="Start typing..." v-model="item.content"></textarea>
				<small class="pull-right" v-if="item.owner != ''">Added by : {{ item.owner }}</small>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Notes',
		components: {
			
		},
		data() {
			return {
				MyNotes: [
					{ id: 1, date: 'Tues 3 April 2020 19:45:10', content: 'This is a sticky note you can type and edit.', owner: 'Mouad' },
					{ id: 2, date: 'Tues 4 April 2020 19:45:10', content: 'This is a sticky note you can type and edit.', owner: 'Mouad' },
				]
			}
		},
		computed : {
			
		},
		methods: {
			addNotes() {
				let that = this
				that.MyNotes.push({ id: that.MyNotes.lenght, date: '', content: '', owner: '' })
			}
		}
	}
</script>
<style lang="scss">
	.close-note {
		height: 22px;
		padding: 3px;
		line-height: 0.6;
	}
	#add-note, .notearea  {
		padding:12px 14px 0px 14px;
		margin:0 20px 20px 0;
		width:250px;
		height:250px;
	}
	
	#add-note {
		user-select:none;
		padding:20px; 
		border-radius:20px;
		text-align:center; 
		border:15px solid rgba(0,0,0,0.1); 
		cursor:pointer;
		color:rgba(0,0,0,0.1);
		font:220px "Helvetica", sans-serif;
		line-height:185px;
		&:hover {
			border-color:rgba(0,0,0,0.2);
			color:rgba(0,0,0,0.2);
		}
	}
	
	.notearea {
		border:0;
		border-radius:3px;
		background: linear-gradient(#F9EFAF, #F7E98D);
		box-shadow:0 4px 6px rgba(0,0,0,0.1);
		overflow:hidden;
		transition:box-shadow 0.5s ease;
		font-smoothing:subpixel-antialiased;
		max-width:520px;
		max-height:250px;
		textarea {
			width: 100%;
			height: 180px;
			padding: 0;
			border: 0px;
			resize: none;
			background-color: transparent;
			padding-top: 5px;
		}
		&:hover { box-shadow:0 5px 8px rgba(0,0,0,0.15); }
		&:focus { box-shadow:0 5px 12px rgba(0,0,0,0.2); outline:none; }
	}
	
</style>
