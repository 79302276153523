<template>
	
	<div class="container-fluid">
		<h4 class="c-grey-900 mT-10 mB-30">My Contacts</h4>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<!--h4 class="c-grey-900 mB-20">Lists</h4-->
					<data-table :dataItems="lists" :columns="columns" @actionClick="actionClick" :selectedItems="selectedItems" :footer="true" :responsive="true">
						<template v-slot:body="slotProps">
							<tr v-for="(entry, id) in slotProps.body" v-bind:key="id">
								<template v-for="(item, key) in columns">
									<td v-if="item.enable" v-bind:key="key">
										<div v-if="item.column == 'actions'" class="text-center">
											<drop-down :items="columns">
												<template v-slot:label>
													<i class="fa fa-ellipsis-v c-pointer p-3 m-auto" style="font-size:18px;color: #2d2d2d;"></i>
												</template>
												<template v-slot:options>
													<li v-for="(item, id) in optionsAction" v-bind:key="`li-${id}`">
														<a href="javascript:void(0);" class="d-b td-n p-5 bgcH-grey-100 c-grey-700">
															<i :class="[item.icon, 'm-5']"></i>
															<span>{{ item.label }}</span>
														</a>
													</li>
												</template>
											</drop-down>
										</div>
										<div v-else-if="item.column == 'list'">
											<div>
												<a href="javascript:void(0);" class="" style="color: rgb(121, 121, 121); display: flex;">
													<img :src="`/img/${entry['img']}`" style="max-width: 3em; margin-right: 14px;">
													<div title="">
														<div>{{ entry[item.column] }}</div>
														<b style="font-size: 11px;">Published on : </b>
														<span style="color: rgb(121, 121, 121);">{{entry['createdAt']}}</span>
													</div>
												</a>
											</div>
										</div>
										<div v-else class="text-center">
											{{ entry[item.column] }}
										</div>
									</td>
								</template>
							</tr>
						</template>
					</data-table>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { DataTable, DropDown } from "@/components";
	export default {
		name: 'MyContacts',
		data() {
			return {
				selectedItems: [],
				columns		 : [
					{ title: "Id"			, column: 'id' 		  , enable: false, class: '' },
					{ title: 'Name'			, column: 'name' 	  , enable: true , class: '' },
					{ title: 'Status'		, column: 'status'	  , enable: true , class: 'text-center' },
					{ title: 'Email'		, column: 'email' 	  , enable: true , class: 'text-center' },
					{ title: 'Phone'		, column: 'phone' 	  , enable: true , class: 'text-center' },
					{ title: 'Generated On'	, column: 'generatedOn', enable: true , class: 'text-center' },
					{ title: 'Actions'		, column: 'actions'   , enable: true , class: 'text-center' }
					
				],
				lists	: [
					{ id : 1, name : '11111', status	: '11111', email : "21", phone : '11111', generatedOn : '2020-04-10 12:54:40' },
					{ id : 2, name : '22222', status	: '22222', email : "21", phone : '22222', generatedOn : '2020-04-10 12:54:40' },
				],
				optionsAction: [
					{id: 0, label: 'Edit', icon: 'ti-pencil'},
					{id: 1, label: 'Preview', icon: 'ti-view-list-alt'},
					{id: 2, label: 'Delete',icon: 'ti-trash'},
				]
			}
		},
		methods: {
			actionClick(ids) {// , action
				this.selectedItems = ids
			},
		},
		mounted() {
			let that = this
			that.$store.state.selectedPage = that.$route.name
		},
		components: {
			//
			DataTable,
			DropDown
		}
	}
</script>
<style lang="scss">
	
</style>