<template>
	
	<div class="container-fluid">
		<div class="row mT-10 mB-30">
			<div class="col-6">
				<h4 class="c-grey-900 m-0">{{ $t('myleads_1') }}</h4>
			</div>
			<div class="col-6 text-right">
				<button @click="OpenModal('AddLead')" class="btn btn-primary btn-sm">
					<i class="fa fa-plus"></i> {{ $t('myleads_2') }}
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 mB-20">
					
					<div class="row border-bottom-light no-gutters"><!-- border-bottom -->
						<div class="col-6 my-auto">
							
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text border-0 bgc-white pL-20 pR-5 text-secondary"><i class="fa fa-search"></i></span>
								</div>
								<input type="text" class="form-control border-0" :placeholder="$t('myleads_3')" v-model="SearchFilter" />
							</div>
							
						</div>
						<div class="col-2">
							<!--div v-if="MyLeads.length > 0" class="pt-2">Showing <b>1</b> to <b>{{ MyLeads.length }}</b> of <b>{{ TotalLeads }}</b> entries</div-->
						</div>
						<div class="col-4 text-right d-flex pr-0 option-tabs">
							
							<button title="Leads actions" @click="ChangeTabs('Action')" 
							:class="['bdrs-0 border-left pX-20 pY-10 ml-auto btn border-bottom-0 border-top-0 border-right-0 text-secondary', OptionsTabs.Action ? 'btn-filter' : '']">
								<i class="fa fa-cogs"></i><!--exchange-->
								<!--i class="ti-panel"></i-->
							</button>
							
							<button title="Export Leads" @click="ChangeTabs('Export')" 
							:class="['bdrs-0 border-left pX-20 pY-10 btn border-bottom-0 border-top-0 border-right-0 text-secondary', OptionsTabs.Export ? 'btn-filter' : '']">
								<i class="ti-export"></i>
							</button>
							
							<button title="Advanced Search" @click="ChangeTabs('Filter')"
							:class="['bdrs-0 border-left pX-20 pY-10 btn border-bottom-0 border-top-0 border-right-0 text-secondary', OptionsTabs.Filter ? 'btn-filter' : '']">
								<i class="fa fa-filter"></i>
							</button>
							
						</div>
					</div>
					
					<div class="row pX-20 pY-15 border-bottom no-gutters" v-show="OptionsTabs.Filter" style="background-color: #d3ebff;"><!--  mB-20 -->
						<div class="col-4">
							<button @click="ModalObject.colsVis = true" class="btn btn-info mr-2" :title="$t('myleads_20')[0]">
								<i class="fa fa-table"></i> {{ $t('myleads_20')[0] }}
							</button>
						</div>
						<div class="col-8">
							<div class="form-inline pull-right">
								<div class="form-group"><!--  text-right -->
									<!--h4 class="c-grey-900">my lists</h4-->
									<multi-select :options="MyLists" :selected="selectedLists" :label="$t('myleads_4')[1]" placeholderType="title" :placeholder="$t('myleads_4')[0]" 
									:defaultSelected="defaultSelected" :selectAll="true" :caret="true" width="170px" @change="getLeads('filter')"></multi-select>
									<!-- faIcon="fa-calendar-check-o"  @filterChange="getLeads(true, 'filter')" defaultSelected="all" -->
								</div>
								<div class="form-group ml-2">
									<multi-select :options="LeadStatuses" :selected="selectedStatus" :label="$t('myleads_4')[3]" placeholderType="title" :placeholder="$t('myleads_4')[2]" 
									:selectAll="true" :caret="true" width="170px" @change="getLeads('filter')"></multi-select>
								</div>
								<div class="form-group ml-2">
									<date-picker class="date-picker-range" right v-model="dateRange"></date-picker>
								</div>
							</div>
						</div>
					</div>
					
					<div class="row pX-20 pY-15 border-bottom no-gutters" v-show="OptionsTabs.Export" style="background-color: #d3ebff;">
						<div class="col-6 pY-2 my-auto">
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" v-model="ExportCols" id="ExportAll" value="all">
								<label class="form-check-label fw-600 text-dark" for="ExportAll">{{ $t('myleads_5') }}</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" v-model="ExportCols" id="ExportSelected" value="selected">
								<label class="form-check-label fw-600 text-dark" for="ExportSelected">{{ $t('myleads_6') }}</label>
							</div>
						</div>
						<div class="col-6 pY-2 text-right">
							
							<button class="btn btn-light mr-2" :title="$t('myleads_7')[0]" @click="ExportAs('csv')" :disabled="loading.csv">
								<i :class="[ loading.csv ? 'fa fa-cog fa-spin' : 'ti-export']"></i> {{ $t('myleads_7')[1] }} ({{ TotalExport }})
							</button>
							<button class="btn btn-light" :title="$t('myleads_7')[0]" @click="ExportAs('xlsx')" :disabled="loading.xlsx">
								<i :class="[ loading.xlsx ? 'fa fa-cog fa-spin' : 'ti-export']"></i> {{ $t('myleads_7')[2] }} ({{ TotalExport }})
							</button>
							
						</div>
					</div>
					
					<div class="row pX-20 pY-15 border-bottom no-gutters" v-show="OptionsTabs.Action" style="background-color: #d3ebff;">
						<div class="col-4 pY-2 my-auto">
							<router-link :to="{path: '/settings/statuses'}" class="btn btn-info mr-2" :title="$t('myleads_8')[6]">
								<i class="fa fa-tags"></i> {{ $t('myleads_8')[6] }}
							</router-link>
						</div>
						<div class="col-8 pY-2 text-right">
							
							<!--span v-if="SelectedItems.length  > 0" class="form-check-label fw-600 text-dark mr-2">{{ SelectedItems.length }} {{ SelectedItems.length > 1 ? $t('myleads_8')[0] : $t('myleads_8')[1] }} {{ $t('myleads_8')[2] }}</span-->
							
							<!--button class="btn btn-danger mr-2" :title="SelectedItems.length == 0 ? $t('myleads_8')[3] : $t('myleads_8')[5]" @click="OpenModal('changestatus')" :disabled="SelectedItems.length == 0">
								<i class="ti-view-list-alt"></i> {{ $t('myleads_8')[5] }} ({{SelectedItems.length}}) (Beta)
							</button-->
							
							<button class="btn btn-light" :title="SelectedItems.length == 0 ? $t('myleads_8')[3] : $t('myleads_8')[4]" @click="OpenModal('changelist')" :disabled="SelectedItems.length == 0">
								<i class="ti-view-list-alt"></i> {{ $t('myleads_8')[4] }} ({{SelectedItems.length}})
							</button>
							
							<!--button class="btn btn-light" :title="SelectedItems.length == 0 ? 'Choose your leads' : 'Set as contact'" @click="OpenModal('setAsContact')" :disabled="SelectedItems.length == 0">
								<i class="fa fa-address-book-o"></i> Set as contact
							</button-->
							
						</div>
					</div>
					
					<div class="p-20">
						<!--h4 class="c-grey-900 mB-20">MyLeads</h4-->
						<data-table id="myleads" :dataItems="MyLeads" :columns="columns" @actionClick="actionClick" :responsive="true" size="sm" :thLength="20"><!-- checkBox -->
							<template v-slot:body="slotProps">
								<tr v-for="(entry, id) in slotProps.body" v-bind:key="id">
									<template v-for="(item, key) in columns">
										<td v-if="item.enable && item.column == 'checkBox'" v-bind:key="`k-${key}`" @click.prevent="checkBoxRow(entry.id)" :style="{width: item.width}">
											<!--div class="custom-control custom-checkbox checkbox-info mr-1">
												<input type="checkbox" class="custom-control-input" :id="`selectItems-${entry.id}`" v-model="SelectedItems" :value="entry.id">
												<label class="custom-control-label" :for="`selectItems-${entry.id}`"></label>
											</div-->
											<check-box :id="`selectItems-${entry.id}`" v-model="SelectedItems" :val="entry.id"></check-box>
										</td>
										<td v-else-if="item.enable && item.column == 'id'" v-bind:key="`key-${key}`" :class="[item.class]" :style="{width: item.width}" @click.prevent="checkBoxRow(entry.id)">
											<div class="pos-r">
												<div class="btn-link cur-p fw-600 h6 m-0" @click="LeadOptions('setting', entry, id)">
													<i class="fa fa-user-o fs-12 w-12"></i>&nbsp;
													<span v-if="entry.full_name != '' && entry.full_name != null" v-html="SearchFormat(entry.full_name)"></span>
													<span v-else v-html="SearchFormat(entry.first_name) + ' ' + SearchFormat(entry.last_name)"></span>
												</div>
												<div><i class="fa fa-envelope-o fs-12 w-12"></i>&nbsp;<span v-html="SearchFormat(entry.email)"></span></div>
												<div><i class="fa fa-phone fs-12 w-12"></i>&nbsp;<span v-html="SearchFormat(entry.phone)"></span></div>
												<span v-if="entry.duplicate.length > 1" class="badge badge-danger fs-8 span-duplicate">{{ $t('myleads_9') }} ({{entry.duplicate.length}})</span>
											</div>
										</td>
										<td v-else-if="item.column == 'options'" v-bind:key="`key-${key}`" :class="[item.class]" :style="{width: item.width}">
											<i v-for="(option, Index) in optionsAction" v-bind:key="`li-${Index}`" :title="option.label" :class="[option.icon, option.cls, 'brd-s bdrs-50p p-4 cur-p']" @click="LeadOptions(option.action, entry, id)"></i>
										</td>
										<td v-else-if="item.enable && item.column == 'email'" v-bind:key="`key-${key}`" :class="[item.class]" :style="{width: item.width}">
											
											<button class="btn btn-link text-decoration-none btn-sm mr-1" @click="CopyText(entry[item.column])" v-if="isIsset(entry.email)" title="Copy">
												<i class="ti-files"></i>
											</button>
											
											<button class="btn btn-link text-decoration-none btn-sm mr-1" @click="LeadAction('sendemail', entry)" v-if="isIsset(entry.email)" title="Send Email">
												<i class="fa fa-envelope-o"></i>
											</button>
											
										</td>
										<td v-else-if="item.enable && item.column == 'phone'" v-bind:key="`key-${key}`" :class="[item.class]" :style="{width: item.width}">
											
											<button class="btn btn-link text-decoration-none btn-sm mr-1" @click="CopyText(entry[item.column])" v-if="isIsset(entry.phone)" title="Copy">
												<i class="ti-files"></i>
											</button>
											
											<a :href="`https://api.whatsapp.com/send?phone=${entry.phone}`" target="_blank" class="btn btn-link text-decoration-none btn-sm mr-1" v-if="isIsset(entry.phone)" title="whatsapp">
												<i class="fa fa-whatsapp text-success fs-14"></i>
											</a>
											
										</td>
										<td v-else-if="item.enable && item.column == 'generated_at'" v-bind:key="`key-${key}`" @click="checkBoxRow(entry.id)" :class="[item.class]" :style="{width: item.width}">
											{{ entry[item.column] | date_format }}
										</td>
										<td v-else-if="item.enable && item.column == 'list_type'" v-bind:key="`key-${key}`" @click="checkBoxRow(entry.id)" :class="[item.class]" :style="{width: item.width}" :title="entry['list_name']">
											<img :src="`/img/sources/${ListTypeImages[entry[item.column]]}`" style="width: 30px;" />
											<!-- :style="{width: entry[item.column] == 'txt' ? '40px' : '25px'}" -->
										</td>
										<td v-else-if="item.enable && item.column == 'status'" v-bind:key="`key-${key}`" @click="checkBoxRow(entry.id)" :class="[item.class]" :style="{width: item.width}">
											<i class="fa fa-circle-o" v-if="MyStatuses[entry[item.column]]['color'] == '#ffffff'"></i> 
											<i class="fa fa-circle" v-else :style="{color: MyStatuses[entry[item.column]]['color']}"></i> 
											{{ MyStatuses[entry[item.column]]['title'] }}
										</td>
										<td v-else-if="item.enable" v-bind:key="`key-${key}`" @click="checkBoxRow(entry.id)" :class="[item.class]" :style="{width: item.width}">
											<div :title="entry[item.column]" v-html="SearchFormat(entry[item.column])">
												<!-- {{ SearchFormat(entry[item.column]) | maxLength }} -->
											</div>
										</td>
									</template>
								</tr>
							</template>
						</data-table>
						
						<modal v-show="ModalObject.Setting" @close="ModalObject.Setting = false" size="lg" title="Lead Details" hide-footer>
							<template v-slot:header>
								<div class="col-12">
									<div class="row border-bottom">
										<div class="col-4 my-auto fs-20">
											<div>{{ $t('myleads_10') }}</div>
										</div>
										<div class="col-8 text-right pr-0">
											<button class="btn btn-default rounded-0 btn-lg border-left py-3 px-4" @click="LeadAction('sendemail', SelectedLead)" v-if="isIsset(SelectedLead.email)" title="Send Email">
												<i class="fa fa-envelope-o"></i>
											</button>
											<a :href="`https://api.whatsapp.com/send?phone=${SelectedLead.phone}`" target="_blank" class="btn btn-default rounded-0 btn-lg border-left py-3 px-4" v-if="isIsset(SelectedLead.phone)" title="whatsapp">
												<i class="fa fa-whatsapp text-success"></i>
											</a>
											
											<button type="button" class="btn btn-default rounded-0 btn-lg border-left py-3 px-4" @click="PrevLead()" :disabled="CurrentLeadId == 0">
												<i class="ti-angle-double-left fs-14"></i>
											</button>
											<button type="button" class="btn btn-default rounded-0 btn-lg border-left py-3 px-4" @click="NextLead()" :disabled="CurrentLeadId + 1 == MyLeads.length">
												<i class="ti-angle-double-right fs-14"></i>
											</button>
											<button type="button" class="btn btn-default rounded-0 btn-lg border-left py-3 px-4" @click="CloseModalSetting">&times;</button>
										</div>
									</div>
								</div>
							</template>
							<template v-slot:body>
								<lead-details :Lead="SelectedLead" :MyStatuses="MyStatuses" :MyFields="MyCustomFields" @onChange="ChangeLeadDetails"></lead-details>
							</template>
						</modal>
						
						<modal size="confirm" v-show="ModalObject.DeleteLead" hide-header hide-footer>
							<template v-slot:body>
								<div class="text-center">
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);width:6em; height:6em;" viewBox="0 0 512 512">
											<path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192s192-86 192-192z" fill="none" stroke="#ea1c0d" stroke-miterlimit="10" stroke-width="32"/>
											<path fill="none" stroke="#ea1c0d" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M320 320L192 192"/>
											<path fill="none" stroke="#ea1c0d" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M192 320l128-128"/>
										</svg>
									</div>
									<div>
										<h4>{{ $t('myleads_11')[0] }}</h4>
										<p class="m-0" style="font-size: 13px;">{{ $t('myleads_11')[1] }}</p>
										<h6 v-if="SelectedLead.email != '' && SelectedLead.email != null">{{  SelectedLead.email }}</h6>
										<h6 v-else-if="SelectedLead.phone != '' && SelectedLead.phone != null">{{  SelectedLead.phone }}</h6>
										<h6 v-else-if="SelectedLead.full_name != '' && SelectedLead.full_name != null">{{  SelectedLead.full_name }}</h6>
									</div>
									<div>
										<button class="btn btn-secondary mR-10" @click="ModalObject.DeleteLead = false">{{ $t('myleads_11')[2] }}</button>
										<button class="btn btn-danger" @click="DeleteLead" :disabled="loading.deleteLead">
											<i class="fa fa-cog fa-spin" v-if="loading.deleteLead"></i> 
											<i class="ti-trash mR-2 default-size" v-else></i> <span>{{ $t('myleads_11')[3] }}</span>
										</button>
									</div>
								</div>
							</template>
						</modal>
						
						<!-- ---------------------------------------------------------------------------------------------------------------- -->
						<modal v-show="ModalObject.changelist" @close="ModalObject.changelist = false" size="sm" title="Change list">
							<template v-slot:body>
								<div class="container-fluid">
									<div class="row" v-for="(list, id) in MyLists" v-bind:key="`list-${id}`">
										<div :class="['col-12 border py-2 rounded mb-2 bg-light', list.id == LeadsSetting.list_id ? 'shadow-sm' : '']"><!--hover-sm-->
											<div class="form-check label-weight">
												<input class="form-check-input" type="radio" v-model="LeadsSetting.list_id" :id="`checklist-${list.id}`" :value="list.id"><!-- checked-->
												<label class="form-check-label flex-container space-between cur-p m-0" :for="`checklist-${list.id}`">
													<div class="my-auto"><img :src="`/img/sources/${ListTypeImages[list.type]}`" style="width: 22px;" /> {{list.option}}</div>
													<small class="my-auto">({{list.TotalLeads}})</small>
												</label>
											</div>
										</div>
									</div>
								</div>
							</template>
							<template v-slot:footer>
								<div class="row">
									<div class="col-8 text-left my-auto">
										<div class="form-check-inline label-weight">
											<input class="form-check-input" type="radio" v-model="LeadsSetting.target" id="selected-only" value="selected"><!-- checked -->
											<label class="form-check-label cur-p m-0" for="selected-only">
												Selected <b>({{SelectedItems.length}})</b>
											</label>
										</div>
										<div class="form-check-inline label-weight">
											<input class="form-check-input" type="radio" v-model="LeadsSetting.target" id="all-leads" value="all">
											<label class="form-check-label cur-p m-0" for="all-leads">
												All <b>({{ TotalLeads }})</b>
											</label>
										</div>
									</div>
									<div class="col-4">
										<!--button type="button" class="btn btn-light mr-2" @click="ModalObject.changelist = false">{{ $t('myleads_12')[0] }}</button-->
										<button type="button" class="btn btn-primary" @click="BulkAction('changelist')" :disabled="loading.changelist || LeadsSetting.list_id == ''">
											<i :class="[ loading.changelist ? 'fa fa-cog fa-spin' : 'fa fa-save']"></i> {{ $t('myleads_12')[1] }}
										</button>
									</div>
								</div>
							</template>
						</modal>
						<!-- ---------------------------------------------------------------------------------------------------------------- -->
						
						<modal v-show="ModalObject.SendEmail" @close="ModalObject.SendEmail = false" size="lg" title="Sending Email">
							<template v-slot:body>
								<div class="row">
									<div class="col-12">
										<email-content :EmailContent="DataEmail" :MySenders="MySenders"></email-content><!-- @onChange="ChangeDetails" -->
									</div>
								</div>
							</template>
							<template v-slot:footer>
								<div class="row">
									<div class="col-12">
										<button type="button" class="btn btn-default mr-2" @click="ModalObject.SendEmail = false">{{ $t('myleads_12')[0] }}</button>
										<button type="button" class="btn btn-primary" @click="SendEmail" :disabled="loading.sendEmail">
											<i :class="[ loading.sendEmail ? 'fa fa-cog fa-spin' : 'fa fa-envelope-o']"></i> {{ $t('myleads_12')[2] }}
										</button>
									</div>
								</div>
							</template>
						</modal>
						
						<modal v-show="ModalObject.AddLead" @close="ModalObject.AddLead = false" size="sm" title="Create Lead">
							<template v-slot:body>
								<div class="row">
									<div class="col-11 ml-auto label-weight">
										<!-- List -->
										<div class="row form-group">
											<div class="col-4 my-auto">
												<label>{{ $t('myleads_13')[0] }}</label>
											</div>
											<div class="col-6 pr-0">
												<input type="text" class="form-control" :placeholder="$t('myleads_13')[1]" v-if="AddLead.newlist" v-model="AddLead.list_name" />
												<span v-else>
													<drop-options v-model="AddLead.list_id" :label="$t('myleads_13')[1]" :options="ListsLeads" max-width></drop-options>
												</span>
											</div>
											<div class="col-2 my-auto pr-0 pl-2">
												<button class="btn btn-link btn-sm px-0" @click="AddLead.newlist = !AddLead.newlist">{{ AddLead.newlist ? $t('myleads_13')[2] : $t('myleads_13')[3] }} {{ $t('myleads_13')[4] }}</button>
											</div>
										</div>
										<!-- Default fields -->
										<div class="row form-group" v-for="(item, id) in LeadFields" :key="`field-${id}`">
											<div class="col-4 my-auto">
												<label>{{ item.title }}</label>
											</div>
											<div class="col-6 pr-0">
												<input type="text" class="form-control" :placeholder="item.title" v-model="AddLead[item.field]" />
											</div>
											<div class="col-2"></div>
										</div>
										
										<!-- Add field button -->
										<div class="row form-group label-weight">
											<div class="col-4 my-auto">
												<label>{{ $t('myleads_14') }}</label>
											</div>
											<div class="col-6 my-auto p-0 text-right">
												<button class="btn btn-outline-danger btn-sm mr-2 rounded-circle" @click="AddLead.CustomFields.splice(AddLead.CustomFields.length - 1, 1)" :disabled="AddLead.CustomFields.length == 0">
													<i class="fa fa-minus"></i>
												</button>
												<button class="btn btn-outline-primary btn-sm rounded-circle" @click="AddLead.CustomFields.push({ field: '', Value: '' })">
													<i class="fa fa-plus"></i>
												</button>
											</div>
											<div class="col-2"></div>
										</div>
										
										<!-- Custom fields -->
										<div class="row form-group" v-for="(item, id) in AddLead.CustomFields" :key="`cfield-${id}`">
											<div class="col-4 my-auto">
												<auto-complete :items="MyCustomFields" placeholder="Field name" @input="customInput(item)" v-model="item.field" :defaultData="item.field"></auto-complete>
											</div>
											<div class="col-6 pr-0">
												<input type="text" class="form-control" :placeholder="item.field == '' ? 'Field value' : item.field" v-model="item.Value" />
											</div>
											<div class="col-2"></div>
										</div>
									</div>
								</div>
							</template>
							<template v-slot:footer>
								<div class="row">
									<div class="col-12">
										<button type="button" class="btn btn-default mr-2" @click="ModalObject.AddLead = false">{{ $t('myleads_15')[0] }}</button>
										<button type="button" class="btn btn-primary" @click="AddNewLead" :disabled="loading.AddLead">
											<i :class="[ loading.AddLead ? 'fa fa-cog fa-spin' : 'fa fa-save']"></i> {{ $t('myleads_15')[1] }}
										</button>
									</div>
								</div>
							</template>
						</modal>
						
						<modal v-show="ModalObject.colsVis" @close="ModalObject.colsVis = false" size="sm" title="Show hide columns">
							<template v-slot:body>
								<div class="container-fluid">
									<vue-draggable v-model="columns" draggable=".item">
										<template v-for="(el, id) in columns">
											<div class="row item mb-1 ml-0" v-if="!['checkBox', 'options'].includes(el.column)" :key="`column-${id}`"><!--v-for="(el, id) in columns"-->
												<div class="col-md-12 border pt-2 pb-2 rounded move-draggable">
													<div class="flex-container">
														<i class="fa fa-arrows my-auto mr-2"></i> 
														<div class="label-weight my-auto">
															<check-box :id="`check-${el.column}`" v-model="el.enable" :label="el.title" class-name="text-capitalize"></check-box>
														</div>
													</div>
												</div>
											</div>
										</template>
									</vue-draggable>
								</div>
							</template>
							<template v-slot:footer>
								<div class="row">
									<div class="col-12">
										<button type="button" class="btn btn-light mr-2" @click="ModalObject.colsVis = false">{{ $t('myleads_15')[0] }}</button>
									</div>
								</div>
							</template>
						</modal>
						
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { DataTable, MultiSelect, CheckBox, DatePicker, Modal, DropOptions, AutoComplete } from "@/components";
	import { vueDraggable } from "@/components/plugin"
	import LeadDetails 		from './Settings/LeadDetails.vue'
	import { EmailContent }	from './Settings'
	import XLSX from 'xlsx'
	export default {
		name: 'MyLeads',
		data() {
			return {
				
				LeadFields	: [
					{ field: 'full_name',  	 title: this.$t('myleads_16')[0] },
					{ field: 'first_name', 	 title: this.$t('myleads_16')[1] },
					{ field: 'last_name',  	 title: this.$t('myleads_16')[2] },
					{ field: 'email',     	 title: this.$t('myleads_16')[3] },
					{ field: 'phone',     	 title: this.$t('myleads_16')[4] },
				],
				AddLead			: { newlist: false, list_id: '', list_name: '', full_name: '', first_name: '', last_name: '', email: '', phone: '', CustomFields: [] },
				ListsLeads		: [],
				LeadStatus		: { status: '', title: '', color: '' },
				ModalObject		: { AddLead: false, DeleteLead: false, Setting: false, SendEmail: false, changelist: false, colsVis: false },
				LeadsSetting	: { list_id: '', target: 'selected' },
				// --------------------------------------------
				DataEmail		: { lead_id: '', name: '', 'from': '', subject: '', text_code: '', text_editor: '', default_editor: 'text_editor', sender: '' },
				// --------------------------------------------
				MySenders		: [],
				
				OptionsTabs		: { Filter: true, Export: false, Action: false },
				ExportCols		: 'selected',
				loading			: { deleteLead:false, editLead:false, csv: false, xlsx: false, sendEmail: false, AddLead: false, changelist: false },
				
				SelectedLead	: {},
				CurrentLeadId	: 0,
				
				dateRange		: '',
				currentPage		: 1,
				lastPage		: 1,
				itemsPerPage	: 25,
				TotalLeads		: 0,
				
				SelectedItems	: [],
				
				MyLeads			: [],
				
				MyLists			: [],
				selectedLists	: [],
				
				defaultSelected	: '',
				
				selectedStatus	: [],
				LeadStatuses	: [],
				MyStatuses		: {},
				MyCustomFields	: [],
				
				SearchFilter	: '',
				
				columns		 	: [
					{ title: this.$t('myleads_18')[0] , column: 'checkBox'  , enable: true , class: '', width: '46px', checked: false },
					{ title: this.$t('myleads_18')[1]	, column: 'id' 	  	  , enable: true , class: '', width: '220px' },
					{ title: this.$t('myleads_18')[11], column: 'options'	  , enable: true , class: 'text-center', width: '90px' }
				],
				
				optionsAction	: [
					{action: 'setting', label: this.$t('myleads_19')[0],	 cls: 'text-primary mR-8', icon: 'ti-settings'}, // ti-settings
					{action: 'delete' , label: this.$t('myleads_19')[1], 	 cls: 'text-danger',       icon: 'ti-trash'},
					// {id: 2, label: 'My Activity', cls: 'text-secondary',   icon: 'ti-view-list-alt'}, // 
				],
				
				ListTypeImages	: {
					'xlsx' 		: 'Excel.png',
					'txt'  		: 'Txt-File.png',
					'facebook'	: 'Facebook.png',
					'webhook'	: 'webhooks.png',
					'manual'	: 'manual.png'
				},
				
			}
		},
		methods: {
			
			customInput(  ) { // item
				
			},
			// ---------------------------------
			BulkAction( action ) {
				let that = this
				if( that.LeadsSetting.list_id == '' ) {
					that.ToastNotify('warning', 'Opps', "Choose your list !")
					return false
				}
				if( !['changelist'].includes(action) ) {
					that.ToastNotify('warning', 'Opps', "Action not exist !")
					return false
				}
				that.loading[action] = true
				let target  = that.LeadsSetting.target
				let data 	= { target: target, leads: that.SelectedItems, list_id: that.LeadsSetting.list_id }
				if( target == 'all' ) {
					data	= { target: target, list_id: that.LeadsSetting.list_id, lists:that.selectedLists, statuses:that.selectedStatus, search: that.SearchFilter, dateRange: that.dateRange }
				}
				let request = { url: '/leads/' + action, method: 'POST', data: data }
				that.$store.dispatch('requests', request).then((response) => {
					
					that.ToastNotify(response.data.status, response.data.title, response.data.message)
					that.loading[action]	 = false
					that.ModalObject[action] = false
					that.getLeads('filter')
					that.SelectedItems = []
					// ------------------------------------------------
					that.ChangeTabs('Filter')
					that.$store.state.loading = true
					let url  	= '/leads/config'
					let method  = 'GET'
					let params 	= {}
					let request = { url: url, method: method, params: params }
					that.$store.dispatch('requests', request).then((response) => {
						let lists  	 	= response.data.lists
						that.MyLists 	= []
						that.ListsLeads	= []
						lists.forEach(function(list) {
							that.MyLists.push( { id : list.id, option : list.name, type : list.type, TotalLeads : list.leads_count } )
							that.ListsLeads.push({ option: list.id, title: list.name, tag: list.leads_count })
						})
					})
					// ------------------------------------------------
				}).catch(( {response} ) => {
					that.loading[action]	 = false
					that.ModalObject[action] = false
					that.ErrorHandler(response)
				})
				
			},
			
			AddNewLead() {
				
				let that = this
				that.loading.AddLead = true
				let data 		 = that.AddLead
				let request = { url: '/lead/add', method: 'POST', data: data }
				that.$store.dispatch('requests', request).then((response) => {
					
					that.ToastNotify(response.data.status, response.data.title, response.data.message)
					that.loading.AddLead = false
					that.ModalObject.AddLead = false
					// location.reload()
					that.getLeads('filter')
					
				}).catch(( {response} ) => {
					that.loading.AddLead = false
					that.ErrorHandler(response)
				})
				
			},
			
			OpenModal(modal) {
				let that = this
				
				// changelist
				that.LeadsSetting.list_id	= ''
				that.LeadsSetting.target	= 'selected'
				that.ModalObject[modal] = true
				
			},
			
			ChangeDetails() {
				
			},
			
			SendEmail() {
				let that = this
				
				// DataEmail		: { name: '', 'from': '', subject: '', text_code: '', text_editor: '', default_editor: 'text_editor', sender: '' },
				if( that.DataEmail[that.DataEmail.default_editor].trim() == '' ) {
					that.ToastNotify('warning', 'Opps !', that.DataEmail.default_editor.replace('_', ' ') + " cannot be empty !")
					return false
				}
				
				that.loading.sendEmail = true
				let data 		 = that.DataEmail
					data['leads']= that.SelectedItems
				let request = { url: '/send/email', method: 'POST', data: data }
				that.$store.dispatch('requests', request).then((response) => {
					
					// that.ToastNotify('success', 'Success', 'Success..')
					that.ToastNotify(response.data.status, response.data.title, response.data.message)
					that.loading.sendEmail = false
					
					that.ModalObject.SendEmail = false
					
				}).catch(( {response} ) => {
					that.loading.sendEmail = false
					that.ErrorHandler(response)
				})
				
			},
			
			LeadAction(action, element) {
				
				let that = this
				if ( action == 'sendemail' ) {
					if( !that.isIsset(element.email) ) {
						return false
					}
					that.DataEmail.lead_id 			= element.id
					that.DataEmail.name				= ''
					that.DataEmail.from				= ''
					that.DataEmail.subject			= ''
					that.DataEmail.text_code		= ''
					that.DataEmail.text_editor		= ''
					that.DataEmail.default_editor	= 'text_editor'
					that.DataEmail.sender			= ''
					that.ModalObject.SendEmail = true
					that.ModalObject.Setting = false
				} else {
					that.LeadStatus.status = element
					that.LeadStatus.title  = that.MyStatuses[element].title
					that.LeadStatus.color  = that.MyStatuses[element].color
				}
				
			},
			// ---------------------------------
			
			ChangeTabs(tab) {
				let that = this
				if( that.OptionsTabs[tab] ) {
					that.OptionsTabs[tab] = !that.OptionsTabs[tab]
				} else {
					Object.keys(that.OptionsTabs).forEach( function(el) {
						that.OptionsTabs[el] = false
					} )
					that.OptionsTabs[tab] = true
				}
			},
			
			ExportAs(type) {
				
				let that = this
				let DataArray = []
				that.loading[type] = true
				
				let MyLists		= that.MyLists, lists = []
				MyLists.forEach(function(list) {
					lists[list.id] = {name:list.option, type:list.type}
				} )
				let UserRole = that.$store.state.user.current_team_role
				if( UserRole == 'viewer' ) {
					that.ToastNotify('warning', 'Opps !', "You don't have permission")
					that.loading[type] = false
					return false
				}
				if( that.SelectedItems.length ) {
					
					that.MyLeads.forEach( function(val) {
						
						let LeadId = parseInt(val.id)
						if ( that.SelectedItems.includes( LeadId ) ) {
							
							let Lead = [], Field = '', Value = '', ColVis = null
							that.columns.forEach( function(item) {
								ColVis	= ( that.ExportCols == 'selected' ) ? item.enable : true
								Field	= item.column
								if ( !['checkBox', 'id', 'list_type', 'options'].includes( item.column ) && ColVis ) {
									
									if( Field.includes("custom_") ) {
										Field = Field.replace('custom_', '')
										Value = val.custom_fields == null ? '' : (that.isIsset(val.custom_fields[Field]) ? val.custom_fields[Field] : '')
										// Field = Field.toUpperCase()
									} else if( Field == 'list_name' ) {
										Value = typeof( lists[val.list_id].name ) != 'undefined' ? lists[val.list_id].name : ''
									} else {
										Value = val[Field]
									}
									Lead[Field] = Value != null ? Value : ''
									
								}
							} )
							DataArray.push(Lead)
							
						}
					} )
					that.DownloadFile(DataArray, type)
					
				} else {
					
					let data 	= {lists: that.selectedLists, statuses: that.selectedStatus, search: that.SearchFilter, dateRange: that.dateRange}
					let request = { url: '/leads/export', method: 'POST', data: data }
					that.$store.dispatch('requests', request).then((response) => {
						
						let MyLeads = response.data.leads
						MyLeads.forEach( function(val) {
							
							let Lead = [], Field = '', Value = '', ColVis = null
							that.columns.forEach( function(item) {
								ColVis	= ( that.ExportCols == 'selected' ) ? item.enable : true
								Field 	= item.column
								if( !['checkBox', 'id', 'list_type', 'options'].includes( Field ) && ColVis ) {
									
									if( Field.includes("custom_") ) {
										Field = Field.replace('custom_', '')
										Value = val.custom_fields == null ? '' : (that.isIsset(val.custom_fields[Field]) ? val.custom_fields[Field] : '')
										// Field = Field.toUpperCase()
									} else if( Field == 'list_name' ) {
										Value 	= typeof( lists[val.list_id].name ) == 'undefined' ? lists[val.list_id].name : ''
									} else {
										Value = val[Field]
									}
									Lead[Field] = Value != null ? Value : ''
									
								}
							} )
							DataArray.push(Lead)
							
						} )
						
						that.DownloadFile(DataArray, type)
						
					}).catch(( {response} ) => {
						that.loading[type] = false
						that.ErrorHandler(response)
					})
					
				}
				
			},
			
			DownloadFile(dataArray, type = 'csv') {
				let that  = this
				let count = dataArray.length
				var today = new Date();
				var dd 	  = String(today.getDate()).padStart(2, '0');
				var mm 	  = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy  = today.getFullYear();
				
				today = mm + '-' + dd + '-' + yyyy;
				
				const data = XLSX.utils.json_to_sheet(dataArray)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'MyLeads-('+today+')-' + count + '.' + type) // csv - xlsx
				that.loading[type] = false
			},
			
			CloseModalSetting() {
				let that = this
				that.ModalObject.Setting = false
				document.removeEventListener('keydown', that.HandleKeyPress);
			},
			
			ChangeLeadDetails( field, NewValue, action, entries ) {
				let that = this
				// DetailsAction
				if( action == 'GeneralDetails' || action == 'ChangeStatus' ) {
					that.MyLeads[that.CurrentLeadId][field] = NewValue
				} else if( action == 'CustomFields' ) {
					that.MyLeads[that.CurrentLeadId]['custom_fields'][field] = NewValue
					that.MyLeads[that.CurrentLeadId]['custom_' + field]		 = NewValue
				} else if( action == 'NewCustomFields' ) {
					that.MyLeads[that.CurrentLeadId]['custom_fields'][field] = NewValue
					that.MyLeads[that.CurrentLeadId]['custom_' + field]		 = NewValue
					that.columns.splice(that.columns.length - 1, 0, { title: field, column: 'custom_' + field, enable: false, class: '', width: '150px' })
				}
				that.MyLeads[that.CurrentLeadId]['lead_activity']		 	 = entries
				// that.SelectedLead  = that.MyLeads[that.CurrentLeadId]
			},
			
			scroll() {
				// Detect when scrolled to bottom.
				const listElm = document.querySelector('.content-myleads');
				let that = this
				listElm.addEventListener('scroll', e => {
					if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
						if( e.type == 'scroll' ) {
							that.getLeads()
						}
					}
				})
			},
			
			LeadOptions(action, lead, index) {
				let that = this
				that.SelectedLead  = lead
				that.CurrentLeadId = index
				if( action == 'setting' ) {
					that.ModalObject.Setting = true
					// detecting arrow key presses
					document.addEventListener('keydown', that.HandleKeyPress);
				} else {
					that.ModalObject.DeleteLead = true
				}
			},
			
			PrevLead() {
				let that = this
				if( that.CurrentLeadId > 0 ) {
					that.CurrentLeadId--
					that.SelectedLead  = that.MyLeads[that.CurrentLeadId]
				}
			},
			
			NextLead() {
				let that = this
				if( that.CurrentLeadId + 1 < that.MyLeads.length ) {
					if( that.CurrentLeadId + 5 >= that.MyLeads.length && !that.$store.state.loading ) {
						that.getLeads()
					}
					that.CurrentLeadId++
					that.SelectedLead  = that.MyLeads[that.CurrentLeadId]
					// that.$store.state.loading = true
				}
			},
			
			DeleteLead() {
				
				let that = this
				that.loading.deleteLead = true
				
				let url  	= '/leads/delete'
				let method  = 'delete'
				let data 	= { id : [that.SelectedLead.id] }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then(() => {
					
					that.MyLeads.splice(that.CurrentLeadId, 1)
					if(that.MyLeads.length < 8) {
						that.getLeads('filter')
					}
					that.loading.deleteLead = false
					that.ModalObject.DeleteLead = false
					that.$store.dispatch('SidebarCounter')
					
				}).catch(( {response} ) => {
					that.loading.deleteLead = false
					that.ModalObject.DeleteLead = false
					that.ErrorHandler(response)
				} )
				
			},
			
			checkBoxRow(item) {
				let that = this
				
				if( !that.SelectedItems.includes(item) ) {
					if( that.SelectedItems.length == that.MyLeads.length - 1 ) {
						that.columns[0].checked = true
					}
					that.SelectedItems.push(item)
				} else {
					let id = that.SelectedItems.indexOf(item)
					that.SelectedItems.splice( id, 1 )
					that.columns[0].checked = null
				}
				
			},
			
			actionClick() { // action
				// let that = this
			},
			
			getLeads(action = '') {
				
				let that 		= this
				let currentPage = action == '' ? that.currentPage : 1
				if( that.lastPage >= currentPage && !that.$store.state.loading ) {
					
					that.$store.state.loading = true
					let url  	= '/leads/get?page=' + currentPage
					let method  = 'POST'
					let data 	= {lists:that.selectedLists, statuses:that.selectedStatus, search: that.SearchFilter, dateRange: that.dateRange, itemsPerPage:that.itemsPerPage}
					let request = { url: url, method: method, data: data }
					that.$store.dispatch('requests', request).then((response) => {
						if( action != '' ) {
							that.MyLeads	= []
							var tblContent	= document.querySelector('.content-myleads');
								tblContent.scrollTop = 0;
							// -----------
						}
						let feedback	 = response.data
						let leads	 	 = feedback.data
						that.currentPage = feedback.current_page + 1
						that.lastPage	 = feedback.last_page
						that.TotalLeads	 = feedback.total
						
						let MyLists		= that.MyLists, lists = []
						MyLists.forEach(function(list) {
							lists[list.id] = {name:list.option, type:list.type}
						} )
						
						let id = 0
						leads.forEach(function(lead) {
							let custom_fields	= lead.custom_fields == null ? {} : lead.custom_fields
								id				= that.MyLeads.length
							that.MyLeads.push({
								id			: lead.id,
								full_name	: (lead.full_name != null && lead.full_name != '') ? lead.full_name.trim() : '',
								first_name	: (lead.first_name != null && lead.first_name != '') ? lead.first_name.trim() : '',
								last_name	: (lead.last_name != null && lead.last_name != '') ? lead.last_name.trim() : '',
								email		: (lead.email != null && lead.email != '') ? lead.email.trim() : '',
								phone		: (lead.phone != null && lead.phone != '') ? lead.phone.trim() : '',
								generated_at: (lead.generated_at == null || lead.generated_at == '') ? lead.created_at : lead.generated_at,
								status		: lead.status,
								custom_fields: custom_fields,
								created_at	: lead.created_at,
								lead_activity: lead.lead_activity != null ? lead.lead_activity.entries : [],
								user		: lead.user_full_name != null ? lead.user_full_name.fullname : '',
								list		: lead.list_id,
								duplicate	: lead.duplicate_email,
								list_name	: typeof( lists[lead.list_id].name ) == 'undefined' ? '' : lists[lead.list_id].name,
								list_type	: typeof( lists[lead.list_id].type ) == 'undefined' ? '' : lists[lead.list_id].type,
							})
							that.MyCustomFields.forEach(function(field) {
								that.MyLeads[id]['custom_' + field]		= that.isIsset(custom_fields[field]) ? custom_fields[field] : ''
							})
						})
						
						if( that.columns[0].checked && that.SelectedItems.length != that.MyLeads.length ) {
							that.columns[0].checked = null
						}
						
						that.$store.state.loading = false
					}).catch(( {response} ) => {
						that.$store.state.loading = false
						that.ErrorHandler(response)
					} )
					
				}
			},
			
			getConfig() {
				// Only lists active ---------------------------------------------------------------------------------------
				let that = this
				that.$store.state.loading = true
				let url  	= '/leads/config'
				let method  = 'GET'
				let params 	= {}
				let request = { url: url, method: method, params: params }
				that.$store.dispatch('requests', request).then((response) => {
					
					that.MySenders	= []
					let senders 	= response.data.senders
					senders.forEach(function(sender) {
						that.MySenders.push( { option : sender.id, title : sender.title, tag: sender.quota + ' Emails', domain: sender.domain } )
					})
					
					that.MyLists 		= []
					let lists  	 		= response.data.lists, statuses = response.data.lead_statuses, CustomFields = response.data.custom_fields
					statuses		 	= JSON.parse( statuses )
					that.MyStatuses 	= statuses
					that.MyCustomFields = CustomFields
					// return response()->json([ 'lead_statuses' => $lead_statuses, 'custom_fields' => $custom_fields, 'lists' => $listsArray ], 200);
					let listsId	 = []
					
					lists.forEach(function(list) {
						listsId.push(list.id.toString())
						that.MyLists.push( { id : list.id, option : list.name, type : list.type, TotalLeads : list.leads_count } )
						that.ListsLeads.push({ option: list.id, title: list.name, tag: list.leads_count })
					})
					
					Object.keys(statuses).forEach(function(status) {
						that.LeadStatuses.push( { id : status, option : statuses[status]['title'], color : statuses[status]['color'] } )
						if( statuses[status]['select'] ) {
							that.selectedStatus.push ( status )
						}
					})
					that.columns = []
					let columns  = {
						'checkBox'  : { title: that.$t('myleads_18')[0] , column: 'checkBox'  , enable: true , class: '', width: '46px', checked: false },
						'id' 	  	: { title: that.$t('myleads_18')[1]	, column: 'id' 	  	  , enable: true , class: '', width: '220px' },
						'full_name' : { title: that.$t('myleads_18')[2] , column: 'full_name' , enable: false, class: '', width: '150px' },
						'first_name': { title: that.$t('myleads_18')[3]	, column: 'first_name', enable: false, class: '', width: '150px' },
						'last_name' : { title: that.$t('myleads_18')[4]	, column: 'last_name' , enable: false, class: '', width: '150px' },
						'email' 	: { title: that.$t('myleads_18')[5]	, column: 'email' 	  , enable: true , class: 'text-center', width: '100px' },
						'phone' 	: { title: that.$t('myleads_18')[6]	, column: 'phone' 	  , enable: true , class: 'text-center', width: '100px' },
						'list_type' : { title: that.$t('myleads_18')[7]	, column: 'list_type' , enable: true , class: 'text-center', width: '100px' },
						'status'	: { title: that.$t('myleads_18')[8]	, column: 'status'	  , enable: true , class: '', width: '130px' },
						'list_name' : { title: that.$t('myleads_18')[9]	, column: 'list_name' , enable: false, class: '', width: '160px' },
						'generated_at':{ title: that.$t('myleads_18')[10], column: 'generated_at',enable: true, class: '', width: '160px' },
						'options'	: { title: that.$t('myleads_18')[11], column: 'options'	  , enable: true , class: 'text-center', width: '90px' }
					}
					let mlVisCols = that.isIsset(that.$store.state.user.config.mlVisCols) ? that.$store.state.user.config.mlVisCols : []
					CustomFields.forEach(function(field) {
						columns['custom_' + field] = { title: field.replace(/_/g, ' '), column: 'custom_' + field, enable: mlVisCols.includes('custom_' + field), class: '', width: '150px' }
					})
					mlVisCols = mlVisCols.length > 0 ? mlVisCols : Object.values(columns)
					mlVisCols.forEach(function(item) {
						columns[item.column].enable = item.enable
						that.columns.push( columns[item.column] )
					})
					
					let idList	= that.$route.params.list
					if (  typeof(idList) != 'undefined' && listsId.includes(idList.toString()) ) {
						that.selectedLists.push(idList)
					} else {
						that.defaultSelected = 'all'
					}
					that.$store.state.loading = false
					// that.getLeads() // md
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
				
			},
			
			SearchFormat (string) {
				let that	 = this
				let feedback = that.isIsset(string) ? string : ''
					feedback = feedback.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;")
				return feedback.replace(that.SearchFilter, '<b>' + that.SearchFilter + '</b>')
						.replace(that.SearchFilter.toLowerCase(), '<b>' + that.SearchFilter.toLowerCase() + '</b>')
			},
			
			HandleKeyPress(e) {
				let that = this
				switch (e.keyCode) {
					case 37:
						// alert('left');
						that.PrevLead()
						break;
					/* case 38:
						alert('up');
						break; */
					case 39:
						// alert('right');
						that.NextLead()
						break;
					/* case 40:
						alert('down');
						break; */
				}
			}
			
		},
		computed: {
			TotalExport() {
				let that = this, Total = 0
				Total = that.SelectedItems.length ? that.SelectedItems.length : that.TotalLeads
				that.ExportCols = that.SelectedItems.length ? 'selected' : 'all'
				return parseInt(Total)
			}
		},
		mounted() {
			let that = this
			that.scroll()
			that.$store.dispatch('SidebarCounter')
		},
		created() {
			let that = this
			that.$store.state.selectedPage = 'leads'// that.$route.name
			that.getConfig()
		},
		components: {
			//
			DataTable,
			MultiSelect,
			CheckBox,
			DatePicker,
			Modal,
			LeadDetails,
			EmailContent,
			DropOptions,
			
			vueDraggable,
			AutoComplete
		},
		watch: {
			dateRange: function() {
				this.getLeads('filter')
			},
			SearchFilter: function() {
				let that = this
				setTimeout(function() {
					that.getLeads('filter')
				}, 1000)
			},
			
			columns: {
				handler: function (val) {
					let that = this
					
					let cols = []
					val.forEach(function(col) {
						cols.push({ column: col.column, enable: col.enable })
					})
					
					that.MyLeads.forEach(function(item) {
						if( val[0].checked == true ) {
							if( !that.SelectedItems.includes(item.id) ) {
								that.SelectedItems.push(item.id)
							}
						} else if( val[0].checked == false ) {
							let id = that.SelectedItems.indexOf(item.id)
							that.SelectedItems.splice( id, 1 )
						}
					})
					
					that.ChangeConfig( { action: 'mlVisCols', content: cols } )// ['id', 'list_type', 'status', 'generated_at', 'custom_country']
				},
				deep: true
			},
		},
		filters: {
			maxLength: function (string) {
				if( string != null && string != '' ) {
					return string.length > 18 ? string.substring(0, 18) + "..." : string
				}
				return ''
			},
		}
	}
</script>
<style lang="scss">
	
	.btn-filter {
		background-color: #d3ebff !important;
		/* color: #6c757d !important; */
	}
	.border-bottom-light {
		border-bottom: 1px solid #d3ebff !important;
	}
	.option-tabs {
		button>i {
			vertical-align: inherit;
		}
	}
	
	.span-duplicate {
		position: absolute;
		bottom: 2px;
		right: 0px;
	}
</style>
