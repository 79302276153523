<template>
	<div>
		
		<div class="row label-weight">
			<div class="col-8 mx-auto">
				<div class="row">
					<div class="form-group col-md-6">
						<label for="From">{{ $t('emlcontent_1') }} <b style="color: red;">*</b></label>
						<drop-options v-model="EmailContent.sender" label="Choose smtp" :options="MySenders" max-width radius="rounded-right" @change="onChange"></drop-options>
					</div>
					<div class="form-group col-md-6">
						<label for="sender_name">{{ $t('emlcontent_2') }} <b style="color: red;">*</b></label>
						<div class="input-group">
							<input id="SenderEmail" v-model.trim="EmailContent.name" placeholder="Sender" class="form-control rounded-left text-right" />
							<div class="input-group-append">
								<span class="input-group-text bg-white">{{ SenderDomain }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-md-6">
						<label for="From">{{ $t('emlcontent_3')[0] }} <b style="color: red;">*</b></label>
						<input id="From" class="form-control" v-model.trim="EmailContent.from" :placeholder="$t('emlcontent_3')[1]" />
					</div>
					
					<div class="form-group col-md-6">
						<label for="subject">{{ $t('emlcontent_4')[0] }} <b style="color: red;">*</b></label>
						<div class="input-group">
							<input id="subject" :class="['form-control']" v-model.trim="EmailContent.subject" :placeholder="$t('emlcontent_4')[1]" />
							<div class="input-group-append">
								<span class="input-group-text bg-white cur-p p-0">
									<!--i class="ti-info-alt"></i-->
									<VueChatEmoji :open="open" @click="ChooseEmoji" :width="width" :height="height"><!-- @toggle="showOrHide" -->
									</VueChatEmoji>
								</span>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row pB-10">
					<div class="col-md-8">
						<button :class="['btn mR-5', OptionTabs == 'text_code' ? 'btn-primary' : 'btn-outline-primary']" @click="ChangeDevice('text_code')">
							<i class="fa fa-code"></i> {{ $t('emlcontent_5')[0] }}
						</button>
						<button :class="['btn mR-5', OptionTabs == 'text_editor' ? 'btn-primary' : 'btn-outline-primary']" @click="ChangeDevice('text_editor')">
							<i class="fa fa-paint-brush"></i> {{ $t('emlcontent_5')[1] }}
						</button>
						<!-- or if you have a template already... -->
						<button :class="['btn mR-5', 'btn-outline-primary']" @click="ModalObj.ChooseTemplate = true">
							<i class="fa fa-columns"></i> {{ $t('emlcontent_5')[2] }}
						</button>
					</div>
					<div class="col-md-4 text-right">
						<button :class="['btn rounded-right-0 pX-20 border-right-0', OptionTabs == 'mobile' ? 'btn-secondary' : 'btn-outline-secondary']" @click="ChangeDevice('mobile')">
							<i class="fa fa-mobile"></i>
						</button>
						<button :class="['btn rounded-0 pX-20 border-right-0', OptionTabs == 'tablet' ? 'btn-secondary' : 'btn-outline-secondary']" @click="ChangeDevice('tablet')">
							<i class="fa fa-tablet"></i>
						</button>
						<button :class="['btn rounded-left-0 pX-20', OptionTabs == 'laptop' ? 'btn-secondary' : 'btn-outline-secondary']" @click="ChangeDevice('laptop')">
							<i class="fa fa-laptop"></i>
						</button>
						<!--button :class="['btn rounded-left-0 pX-20', OptionTabs == 'desktop' ? 'btn-secondary' : 'btn-outline-secondary']" @click="ChangeDevice('desktop')"><i class="fa fa-desktop"></i></button-->
					</div>
				</div>
				
				<div class="row" style="max-height: 34em;">
					<div class="col-9 mB-10"><!-- text-center -->
						<label class="mr-3 mb-0">{{ $t('emlcontent_6')[0] }}</label>
						<div class="custom-control custom-radio custom-control-inline">
							<input type="radio" id="text_code" value="text_code" class="custom-control-input" v-model="DefaultEditor">
							<label class="custom-control-label" for="text_code">{{ $t('emlcontent_6')[1] }}</label>
						</div>
						<div class="custom-control custom-radio custom-control-inline">
							<input type="radio" id="text_editor" value="text_editor" class="custom-control-input" v-model="DefaultEditor">
							<label class="custom-control-label" for="text_editor">{{ $t('emlcontent_6')[2] }}</label>
						</div>
					</div>
					
					<div class="col-3 mB-10 my-auto text-right">
						<button class="btn btn-sm btn-link" @click="ToggleTags = !ToggleTags">
							{{ $t('emlcontent_7') }} <i :class="['fa', ToggleTags ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
						</button>
					</div>
					<div :class="['col-12 my-auto expandable', ToggleTags ? 'active pB-10' : '']">
						<button v-for="(tag, id) in LeadTags" v-bind:key="`tag-${id}`" @click="CopyText(tag)" 
						title="Copy" class="btn btn-sm btn-light mr-1 mb-1">{{ tag }}</button>
					</div>
					<div class="col-12" v-if="OptionTabs == 'mobile'"><smart-phone :content="DeviceContent"></smart-phone></div>
					<div class="col-12" v-else-if="OptionTabs == 'tablet'"><tablet :content="DeviceContent"></tablet></div>
					<div class="col-8 mx-auto" v-else-if="OptionTabs == 'laptop'"><laptop :content="DeviceContent"></laptop></div>
					<div class="col-12" v-else-if="OptionTabs == 'text_code'">
						<codemirror v-model="EmailContent.text_code" :options="CodeOptions"></codemirror>
						<small class="form-text text-danger" v-if="EmailContent.text_code == '' && DefaultEditor == 'text_code'">{{ $t('emlcontent_8') }}</small>
						<!-- EmailContent.text_code -->
					</div>
					<div class="col-12" v-else-if="OptionTabs == 'text_editor'">
						<small class="form-text text-danger" v-if="EmailContent.text_editor == '' && DefaultEditor == 'text_editor'">{{ $t('emlcontent_8') }}</small>
						<quill-editor v-model="EmailContent.text_editor"></quill-editor>
					</div>
				</div>
				
			</div>
		</div>
		
		<div :class="['overlay', ModalPreview ? 'active' : '']">
			<div class="popup">
				<i class="fa fa-close close cur-p" @click="ModalPreview = !ModalPreview"></i>
				<div class="content">
					<div class="blue-btn">
						<span :class="['first-link', ScrollDown ? 'scroll-down' : 'm-auto']">
							<img :src="UrlPreview" /> 
						</span>
					</div>
				</div>
			</div>
		</div>
		
		<modal v-show="ModalObj.ChooseTemplate" @close="ModalObj.ChooseTemplate = false" size="lg" title="Choose Template">
			<template v-slot:body>
				
				<div>
					<ul class="page-item-wrapper">
						<li class="page-item border" v-for="(item, id) in EmailTemplates" :key="`template-${id}`">
							<div class="header-mask">
								<div class="details-btn" @click="ImgPreview(item)">{{ $t('emlcontent_9')[0] }}</div>
							</div>
							<div class="cover">
								<img :src="`${item.thumbnail}`" :alt="item.title" />
							</div>
							<div class="page-item-title border-top">
								<span class="item-title-i">{{ item.title }}</span>
							</div>
							<div class="page-item-data-pv border-top">
								<div class="col-12 text-center">
									<button class="btn btn-sm btn-link" @click="LoadTemplate(item)" :disabled="item.loading">
										<span v-html="(item.category == 'builder' && item.type == 'email') ? $t('emlcontent_9')[1] : $t('emlcontent_9')[2]"></span> <i class="fa fa-cog fa-spin" v-if="item.loading"></i>
									</button>
								</div>
							</div>
						</li>
					</ul>
				</div>
				
			</template>
		</modal>
		
		<modal v-show="ModalObj.EditContent" @close="ModalObj.EditContent = false" size="max" title="Edit Template" hide-header body-class="p-0">
			<template v-slot:body>
				<div>
					<iframe :src="IframeLink" id="iframe-editor" style=" width: 100%; height: 95vh; border: 0px; "></iframe>
				</div>
			</template>
			
			<template v-slot:footer>
				<div class="col-8 my-auto">
					<span class="text-danger">{{ $t('emlcontent_10')[0] }} <i class="fa fa-save text-dark"></i> {{ $t('emlcontent_10')[1] }}</span>
				</div>
				<div class="col-4 text-right pr-0">
					<button type="button" class="btn btn-primary mr-3" @click="UseTemplate" :disabled="Loading.UseTemplate">
						<i :class="['fa', Loading.UseTemplate ? 'fa-cog fa-spin' : 'fa-upload']"></i> {{ $t('emlcontent_10')[2] }}
					</button>
					<button type="button" class="btn btn-secondary" @click="DiscardChanges">{{ $t('emlcontent_10')[3] }}</button>
				</div>
			</template>
		</modal>
		
	</div>
</template>
<script>
	import { QuillEditor, Modal, SmartPhone, Tablet, Laptop, DropOptions } from "@/components";
	
	import { codemirror } from 'vue-codemirror'
	import 'codemirror/mode/xml/xml.js'
	import 'codemirror/lib/codemirror.css'
	import 'codemirror/theme/monokai.css'
	
	import { VueChatEmoji } from "@/components/plugin/emoji/main";
	
	export default {
		name: 'EmailContent',
		components: {
			QuillEditor,
			Modal,
			SmartPhone,
			Tablet,
			Laptop,
			codemirror,
			
			VueChatEmoji,
			DropOptions
		},
		props: {
			EmailContent: { type: Object },
			MySenders	: { type: Array }
		},
		data() {
			return {
				SenderDomain	: '@senderlead.com',
				open	: false,
				width	: "305px",
				height	: "250px",
				
				CodeOptions	: {
					tabSize		: 4,
					styleActiveLine: true,
					lineNumbers	: true,
					line		: true,
					mode		: 'text/html',
					lineWrapping: true,
					theme		: 'monokai',
					htmlMode	: true,
					// styleSelectedText: true,
				},
				ModalObj		: { ChooseTemplate: false, EditContent: false },
				EmailTemplates	: [],
				EmailTemplate	: {},
				Loading			: { UseTemplate: false },
				IframeLink		: '',
				
				StateObject		: {},
				// EmailContent	: { id: '', name: '', 'from': '', subject: '', text_code: '', text_editor: '', default_editor: '', sender: '' },
				
				OptionTabs		: 'text_editor',
				DeviceContent	: '',
				DefaultEditor	: 'text_editor',
				CheckDraft		: false,
				
				
				ModalPreview	: false,
				UrlPreview		: '',
				ScrollDown		: false,
				
				LeadTags		: [],
				ToggleTags		: false
			}
		},
		computed : {
			
		},
		methods: {
			onChange(obj) {
				let that = this
				if( obj != 'input') {
					that.MySenders.forEach( function(item) {
						if( obj.option == item.option ) {
							that.SenderDomain = that.isIsset(item.domain) ? '@' + item.domain.domain : '@senderlead.com'
						}
					} )
				}
				// that.$emit('onChange', that.EmailContent)
			},
			
			getTags() {
				let that = this
				let url  	= '/leads/config'
				let method  = 'GET'
				let params 	= {}
				let request		= { url: url, method: method, params: params }
				that.LeadTags 	= ['[full_name]', '[first_name]', '[last_name]', '[phone]', '[domain]']
				that.$store.dispatch('requests', request).then((response) => {
					response.data.custom_fields.forEach( function(field) {
						that.LeadTags.push('['+field+']')
					} )
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
				} )
			},
			
			ChooseEmoji(obj) {
				this.EmailContent.subject += obj.emoji
			},
			DiscardChanges() {
				
				let that = this
				that.ModalObj.EditContent = false
				
				let url		= '/template/discard'
				let data	= { id: that.EmailTemplate.id }
				let request = { url: url, method: 'POST', data: data }
				that.$store.dispatch('requests', request).then(() => { // response
					
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
				} )
				
			},
			
			ImgPreview(item) {
				let that = this
				that.ModalPreview = true
				that.UrlPreview = item.thumbnail
				// that.ScrollDown = item.category == 'landingpage'
			},
			
			UseTemplate() {
				let that = this
				
				// let filename = that.IframeLink.replace('https://cdn.senderlead.com/editor/index.php?p=', '')
				let url		 = '/template/get'
				let data	 = { id: that.EmailTemplate.id }
				let request  = { url: url, method: 'POST', data: data }
				
				that.Loading.UseTemplate = true
				that.$store.dispatch('requests', request).then((response) => {
					that.Loading.UseTemplate = false
					let status 	= response.data.status
					if( status == 'success' ) {
						
						that.EmailContent.text_code  = response.data.message
						that.DeviceContent			 = response.data.message
						that.ModalObj.ChooseTemplate = false
						that.ModalObj.EditContent   = false
						
						that.DefaultEditor = 'text_code'
						that.EmailContent.default_editor = 'text_code'
						that.OptionTabs    = 'text_code'
						
					} else {
						that.ToastNotify('warning', 'Opps!', response.data.message)
					}
					
				}).catch(( {response} ) => {
					that.Loading.UseTemplate = false
					that.ErrorHandler(response)
				} )
				
			},
			
			ChangeDevice(element) {
				let that = this
				
				that.OptionTabs = element
				if( element == 'text_code' || element == 'text_editor' ) {
					that.EmailContent.default_editor = element
				} else {
					// that.DeviceContent = that.EmailContent[that.EmailContent.default_editor]
					that.DeviceContent = that.EmailContent[that.DefaultEditor]
				}
			},
			
			
			GetEmailTemplates() {
				let that = this
				
				let url  	= '/email/templates'
				let request = { url: url, method: 'GET', params: {} }
				that.$store.dispatch('requests', request).then((response) => {
					that.EmailTemplates = []
					let EmailTemplates  = response.data.EmailTemplates
					let MyTemplates   	= response.data.MyTemplates
					MyTemplates.forEach(function(template) {
						that.EmailTemplates.push( {
							id	 		: template.id,
							title 		: template.name,
							type		: template.type,
							category	: 'builder',
							status 		: template.status,
							thumbnail	: template.thumbnail == null ? require('@/assets/Builder/images/template.png') : template.thumbnail,
							created_at 	: template.created_at,
							loading		: false
						} )
					})
					EmailTemplates.forEach(function(template) {
						that.EmailTemplates.push( {
							id	 		: template.id,
							title 		: template.title,
							type		: template.type,
							category	: template.category,
							status 		: template.status,
							thumbnail	: 'https://cdn.senderlead.com/assets/img/thumbnail/' + template.thumbnail,
							created_at 	: template.created_at,
							loading		: false
						} )
					})
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
				} )
				
			},
			
			LoadTemplate(item) {
				let that = this
				
				Object.keys(item).forEach( function (col) {
					that.EmailTemplate[col] = item[col]
				})
				
				let url		= '/template/use'
				let data	= { id: item.id, type: item.type, category: item.category }
				let request = { url: url, method: 'POST', data: data }
				
				item.loading = true
				that.$store.dispatch('requests', request).then((response) => {
					
					item.loading = false
					let status 	= response.data.status
					if( status == 'success' ) {
						
						if( item.category == 'builder' ) {
							that.EmailContent.text_code  = response.data.message
							that.DeviceContent			 = response.data.message
							that.ModalObj.ChooseTemplate = false
							that.DefaultEditor = 'text_code'
							
							that.EmailContent.default_editor = 'text_code'
							// that.DefaultBody   = 'text_code'
							that.OptionTabs   = 'text_code'
							
						} else {
							let filename 	= response.data.filename // basic/index.html
							that.IframeLink = 'https://cdn.senderlead.com/editor/index.php?p=' + filename
							that.ModalObj.EditContent = true
						}
						
					} else {
						that.ToastNotify('warning', 'Opps!', response.data.message)
					}
					
				}).catch(( {response} ) => {
					item.loading = false
					that.ErrorHandler(response)
				} )
				
			},
			
		},
		mounted() {
			let that = this
			that.GetEmailTemplates()
			that.getTags()
		},
		watch: {
			DefaultEditor(DefaultBody) {
				let that		   = this
				that.EmailContent.default_editor = DefaultBody
				that.DeviceContent = that.EmailContent[DefaultBody]
				
				if( that.EmailContent[DefaultBody] == '' ) {
					that.OptionTabs = DefaultBody
				}
			},
			
			EmailContent: {
				handler(val) {
					// val.match(/[a-z]+|[^a-z]+/g)
					// val.name.match(/[a-z]|[1-9]+/g)
					if( val.name != '' ) {
						let name = val.name.replace(/ /g, '')
							name = name.match(/[a-z]|[1-9]+/ig)
							name = (name != null && name.length > 0) ? name.join('') : ''
						val.name = name
					}
					// let re = /[^A-Z0-9]/gi;
					// this.$set(this, 'myAlphaNumField', newVal.replace(re, ''));
					
				},
				deep: true
			}
			
		}
	}
</script>
