<template>
	<div>
		
		<div v-for="(dateline, id) in LeadActivity" v-bind:key="`dateline-${id}`" class="timeline-item" :date-is="id">
			<div v-for="(activity, key) in dateline" v-bind:key="`activity-${key}`">
				<h5 class="m-0"><i class="fa fa-arrow-circle-o-right"></i> {{ TitleActivity[activity.action] }}</h5>
				<div class="pL-20">
					<small><span><i class="ti-time mR-5"></i>{{ activity.date | date_format }}</span><span> - </span><span><i class="ti-user mR-5"></i>{{ activity.username }}</span></small>
					<div v-if="['ChangeStatus', 'GeneralDetails', 'CustomFields'].includes(activity.action)" class="pB-10">
						<template v-if="activity.field == 'status'">
							<div class="row">
								<div class="col-2 fw-600">{{ $t('myactivity_1') }}</div> 
								<div class="col-10">: 
									<i class="fa fa-circle-o" v-if="StatusColor(activity.old_value) == '#ffffff'"></i> 
									<i class="fa fa-circle" v-else :style="{color: StatusColor(activity.old_value)}"></i> 
									{{ StatusTitle(activity.old_value) }}
								</div>
							</div>
							<div class="row">
								<div class="col-2 fw-600">{{ $t('myactivity_2') }}</div>
								<div class="col-10">: 
									<i class="fa fa-circle-o" v-if="StatusColor(activity.new_value) == '#ffffff'"></i> 
									<i class="fa fa-circle" v-else :style="{color: StatusColor(activity.new_value)}"></i> 
									{{ StatusTitle(activity.new_value) }}
								</div>
							</div>
						</template>
						<div class="row" v-else>
							<div class="col-2 fw-600">{{ $t('myactivity_3')[0] }}</div><div class="col-10">: {{ activity.field.replace('_', ' ') }}</div>
							<div class="col-2 fw-600">{{ $t('myactivity_3')[1] }}</div><div class="col-10">: {{ activity.old_value }}</div>
							<div class="col-2 fw-600">{{ $t('myactivity_3')[2] }}</div><div class="col-10">: {{ activity.new_value }}</div>
						</div>
					</div>
					<div class="row" v-else-if="activity.action == 'NewCustomFields'">
						<div class="col-2 fw-600">{{ $t('myactivity_4')[0] }}</div><div class="col-10">: {{ activity.field.replace('_', ' ') }}</div>
						<div class="col-2 fw-600">{{ $t('myactivity_4')[1] }}</div><div class="col-10">: {{ activity.new_value }}</div>
					</div>
					<p v-else-if="activity.action == 'NewLead'" class="m-0 pT-5">{{ activity.note }}</p>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
	export default {
		name: 'MyActivity',
		components: {
			
		},
		props: {
			Lead		: { type: Object },
			MyStatuses	: { type: Object },
		},
		data() {
			return {
				LeadActivity : {},
				TitleActivity: {
					'NewLead'		 : this.$t('myactivity_5')[0],
					'ChangeStatus'	 : this.$t('myactivity_5')[1],
					'GeneralDetails' : this.$t('myactivity_5')[2],
					'CustomFields'	 : this.$t('myactivity_5')[3],
					'NewCustomFields': this.$t('myactivity_5')[4],
				}
			}
		},
		computed : {
			
		},
		methods: {
			StatusColor(val) {
				let that = this
				if( typeof(that.MyStatuses[val]) != 'undefined' ) {
					return that.MyStatuses[val]['color']
				}
				return ''
			},
			StatusTitle(val) {
				let that = this
				if( typeof(that.MyStatuses[val]) != 'undefined' ) {
					return that.MyStatuses[val]['title']
				}
				try { return atob(val) }
				catch (err) {
					return val
				}
			},
			DateFormatter(MyDate) {
				
				let DateActivity =  new Date(MyDate)
				let DayActivity	 = DateActivity.getDate()
				let MonthActivity= DateActivity.getMonth()
				let YearActivity = DateActivity.getFullYear()
				
				let DayFormat 	 = ((DayActivity < 10) ? '0' : '') + DayActivity
				let MonthFormat	 = ((MonthActivity < 9) ? '0' : '') + (MonthActivity + 1)
				let YearFormat 	 = YearActivity
				
				let DateFormat 	 = DayFormat + '-' + MonthFormat + '-' + YearFormat
				return DateFormat
				
			}
		},
		watch: {
			
			Lead: {
				handler(val) {
					let that = this, lead_activity = val.lead_activity, created_at = val.created_at, entries = [], DateActivity = '', DateFormat = ''
					that.LeadActivity = {}
					
					lead_activity.forEach( function(entry) {
						DateActivity = that.DateFormatter(entry.date)
						if( DateFormat == DateActivity ) {
							entries.push( { "action":entry.action, "username": entry.username, "date":entry.date, "field":entry.field, "old_value":entry.old_value, "new_value":entry.new_value, "note":entry.note } )
						} else {
							entries		= []
							DateFormat 	= DateActivity
							entries.push( { "action":entry.action, "username": entry.username, "date":entry.date, "field":entry.field, "old_value":entry.old_value, "new_value":entry.new_value, "note":entry.note } )
						}
						that.LeadActivity[DateFormat] = entries
					} )
					DateFormat = that.DateFormatter(created_at)
					if( DateFormat != DateActivity ) {
						entries		= []
					}
					entries.push( { "action":'NewLead', "username": val.user, "date":created_at, "field":'', "old_value":'', "new_value":'', "note":'New Lead created' } )
					that.LeadActivity[DateFormat] = entries
					
				},
				deep:true
			}
		},
		filters: {
			date: function(myDate) {
				if (!myDate) { return '(n/a)'; }
				let str = new Date(myDate);
				return ((str.getMonth() < 9) ? '0' : '') + (str.getMonth() + 1) + '/' +  ((str.getDate() < 10) ? '0' : '') + str.getDate() + '/' + str.getFullYear();
			}
		}
	}
</script>
<style lang="scss">
	
	// ----------------  THE BASICS
	
	$border-color: rgba(black, .3);
	
	.timeline-item {
		padding: 3em 2em 2em 4em;
		position: relative;
		color: rgba(black, .7);
		border-left: 2px solid $border-color;
		
		&::before {
			content: attr(date-is);
			position: absolute;
			left: 2em;
			font-weight: bold;
			top: 1em;
			display: block;
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			font-size: .785rem;
		}

		&::after {
			width: 10px;
			height: 10px;
			display: block;
			top: 1.2em;
			position: absolute;
			left: -6px;
			border-radius: 10px;
			content: '';
			border: 2px solid $border-color;
			background: white;
		}

		&:last-child {
			border-image: linear-gradient(
				to bottom,
				$border-color 60%,
				rgba($border-color, 0)) 1 100%
			;
		}
	}
</style>
