<template>
	
	<div class="container-fluid">
		<div class="row mT-10 mB-30">
			<div class="col-6">
				<h4 class="c-grey-900 m-0">{{ $t('newticket_1') }}</h4>
			</div>
			<div class="col-6 text-right my-auto">
				<router-link :to="{name: 'ticket'}" class="btn btn-primary btn-sm">
					<i class="fa fa-plus"></i> {{ $t('newticket_2') }}
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="bgc-white bd bdrs-3 p-20 mB-20">
					<!--div class="row mB-20">
						<div class="col-3"></div>
						<div class="col-9 text-right"></div>
					</div-->
					
					<simple-table :dataItems="tickets" :columns="columns" :footer="true" :responsive="true">
						<template v-slot:body="slotProps">
							<tr v-for="(entry, id) in slotProps.body" v-bind:key="`entry-${id}`">
								<template v-for="(item, key) in columns">
									<td v-if="item.enable" v-bind:key="key">
										<div v-if="item.column == 'actions'" class="text-center">
											<drop-down :items="columns">
												<template v-slot:label>
													<i class="fa fa-ellipsis-v c-pointer p-3 m-auto" style="font-size:18px;color: #2d2d2d;"></i>
												</template>
												<template v-slot:options>
													<li v-for="(item, index) in optionsAction" v-bind:key="`li-${index}`">
														<a href="javascript:void(0);" class="d-b td-n p-5 bgcH-grey-100 c-grey-700" @click="ticketAction(item, entry)">
															<i :class="[item.icon, 'mR-5 mL-5']"></i>
															<span>{{ item.label }}</span><span v-if="item.id == 'response'"> ({{ entry['responses'].length }})</span>
														</a>
													</li>
												</template>
											</drop-down>
										</div>
										<div v-else-if="item.column == 'subject'">
											<div>{{ entry[item.column] | maxLength }}</div>
											<b style="font-size: 11px;">Opened on : </b>
											<span style="color: rgb(121, 121, 121);">{{ entry['created_at'] | date_format }}</span>
										</div>
										<div v-else-if="item.column == 'status'" class="text-center">
											<div :class="[statusColor[entry[item.column]]]">
												<i class="fa fa-circle"></i> {{ entry[item.column] | capitalize }}
											</div>
										</div>
										<div v-else-if="item.column == 'responses'" class="text-center">
											{{ entry[item.column].length }}
										</div>
										<div v-else class="text-center">
											{{ entry[item.column] }}
										</div>
									</td>
								</template>
							</tr>
						</template>
					</simple-table>
					
					<modal v-show="ModalObj.View" @close="ModalObj.View = false" size="md" title="Messages">
						<template v-slot:body>
							
							<div class="card">
								<div class="card-header">
									<div class="row">
										<div class="col-md-8"><h5 class="card-title m-0">{{  ObjMessage.email }}</h5></div>
										<div class="col-md-4 text-right my-auto">{{ ObjMessage.created_at | date_format }}</div>
									</div>
								</div>
								<div class="card-body body-message">
									<p><b>Subject: </b> {{ ObjMessage.subject | capitalize }}</p>
									<p class="card-text">
										<b class="d-block">Message: </b> {{ ObjMessage.message | capitalize }}
									</p>
								</div>
							</div>
							
						</template>
					</modal>
					
					<modal v-show="ModalObj.Responses" @close="ModalObj.Responses = false" size="md" title="Responses" hide-footer>
						<template v-slot:body>
							
							<div>
								<div class="card">
									<div class="card-header">
										<div class="row">
											<div class="col-md-8"><b class="card-title m-0">{{ $t('newticket_3')[0] }}</b> {{ ObjMessage.subject | capitalize | maxLength }}</div>
											<div class="col-md-4 text-right my-auto">{{ ObjMessage.created_at | date_format }}</div>
										</div>
									</div>
									<div class="card-body body-message">
										<div v-for="(item, id) in ObjMessage.responses" v-bind:key="`resp-${id}`" class="alert alert-success">
											<div class="row mB-10">
												<div class="col-6">
													<b v-if="$store.state.user.id == item.user">{{ $t('newticket_3')[1] }}</b>
													<b v-else>{{ $t('newticket_3')[2] }}</b>
												</div>
												<div class="col-6 text-right">{{ item.date }}</div>
											</div>
											<p class="m-0">{{ item.message | capitalize }}</p>
										</div>
									</div>
									
								</div>
								<div class="form-group label-weight mT-10" v-show="ObjMessage.responses.length > 0">
									<label for="RespondArea">{{ $t('newticket_3')[3] }}</label>
									<textarea :class="['form-control', isInvalid ? 'is-invalid':'']"
									id="RespondArea" rows="3" placeholder="Your Message" v-model="NewMessage"></textarea>
									<button class="btn btn-primary pull-right mT-10" @click="Respond" :disabled="processing">
										<i class="fa fa-cog fa-spin" v-if="processing"></i> {{ $t('newticket_3')[4] }}
									</button>
								</div>
							</div>
						</template>
					</modal>
					
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	import { SimpleTable, DropDown, Modal } from "@/components";
	export default {
		name: 'MyTickets',
		data() {
			return {
				NewMessage	 : '',
				isInvalid	 : false,
				processing	 : false,
				
				ModalObj 	 : { View : false, Responses : false },
				statusColor: { opened: 'text-info', suspended: 'text-primary', resolved: 'text-success', closed: 'text-secondary' },
				columns		 : [
					{ title: this.$t('newticket_4')[0]	, column: 'id' 		  , enable: false, class: '' },
					{ title: this.$t('newticket_4')[1]	, column: 'subject'   , enable: true , class: '' },
					{ title: this.$t('newticket_4')[2]	, column: 'department', enable: true , class: 'text-center' },
					{ title: this.$t('newticket_4')[3]	, column: 'status' 	  , enable: true , class: 'text-center' },
					{ title: this.$t('newticket_4')[4]	, column: 'responses' , enable: true , class: 'text-center' },
					{ title: this.$t('newticket_4')[5]	, column: 'actions'   , enable: true , class: 'text-center' }
				],
				tickets : [],
				optionsAction: [
					{id: 'view', 	 label: this.$t('newticket_5')[0], icon: 'fa fa-eye'},
					{id: 'response', label: this.$t('newticket_5')[1], icon: 'fa fa-comments-o'},
					// {id: 1, label: 'Delete',icon: 'fa fa-trash'},
					// {id: 1, label: 'Close',icon: 'fa fa-close'},
				],
				ObjMessage: {
					email : '', subject : '', department: '', status : '', created_at : '', message : '', responses : []
				}
				
			}
		},
		methods: {
			Respond() {
				let that = this
				
				that.isInvalid	= ( that.NewMessage == '' )
				
				if( !that.isInvalid ) {
					that.processing = true
					
					let url  	= '/ticket/respond'
					let method  = 'POST'
					let data 	= { ticket: that.ObjMessage.id, message: that.NewMessage }
					let request = { url: url, method: method, data: data }
					
					that.$store.dispatch('requests', request).then(() => {
						
						that.GetTickets()
						that.ModalObj.Responses	= false
						that.processing 		= false
						that.NewMessage			= ''
						
					}).catch(({response}) => {
						that.ErrorHandler(response)
						that.processing = false
					} )
					
				}
			},
			
			ticketAction(action, entry) {
				let that = this
				Object.keys(entry).forEach( function(item) {
					that.ObjMessage[item] = entry[item]
				} )
				that.ModalObj.View 		   = ( action.label == 'View' )
				that.ModalObj.Responses	   = ( action.label == 'Responses' )
			},
			
			GetTickets() {
				
				let that = this
				that.$store.state.loading = true
				
				let departments = {
					'GeneralQuestion'	: that.$t('newticket_6')[0],
					'TechnicalSupport'	: that.$t('newticket_6')[1],
					'PaymentBilling'	: that.$t('newticket_6')[2],
					'AccountDetails'	: that.$t('newticket_6')[3],
					'Renewal'			: that.$t('newticket_6')[4],
				}
				
				let url  	= '/account/tickets'
				let method  = 'GET'
				let params 	= {}
				let request = { url: url, method: method, params: params }
				
				that.$store.dispatch('requests', request).then((response) => {
					that.tickets= []
					let tickets = response.data
					tickets.forEach(function( item ) {
						that.tickets.push({
							id 			: item.id,
							subject 	: item.subject,
							department	: departments[item.department],
							status 		: item.status,
							email 		: item.email,
							created_at 	: item.created_at,
							responses 	: item.responses,
							message 	: item.message
						})
					})
					
					that.$store.state.loading = false
				}).catch(( {response} ) => {
					that.ErrorHandler(response)
					that.$store.state.loading = false
				} )
				
			},
			
		},
		mounted() {
			let that = this
			that.$store.state.selectedPage = that.$route.name
			that.GetTickets()
		},
		components: {
			//
			SimpleTable,
			DropDown,
			Modal
		},
		
		filters: {
			maxLength: function (str) {
				let count = 40
				if( str != null && str != '' ) {
					return str.trim().length > count ? str.trim().substring(0, count) + " ...." : str.trim()
				} else {
					return ''
				}
			}
		},
	}
</script>
<style lang="scss">
	.body-message {
		max-height: 20em;
		overflow-y: scroll;
	}
</style>